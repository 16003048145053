(function() {
    'use strict';

    app.constant("Modernizr", Modernizr);
    angular.module('llax.app', [
        'angulartics',
        'llax.directives',
        'llax.services',
        'llax.translations',
        'ng.shims.placeholder',
        'ngResource',
        'ngSanitize',
        'pascalprecht.translate',
        'ui.select',
        'ui.tree'
    ])
    .run(function($anchorScroll, $cookies, $dialogs, $document, $filter, $http, $injector, $interval, $location, $log, $modal, $modalStack, $parse, $q,
                  $rootScope, $sce, $templateCache, $timeout, $translate, $window, growl, rowSorter,
                  AdditionalCategoryService, AppConstants, Auth, ChannelService, CodelistRessource, ContactsResource,
                  DataModelResource, DefaultUiConfig, EnumAttributeService, ErrorCode, EtagLoader, HttpHeader,
                  InputTemplatesService, LocalCacheService, MultiDimensionalAttributeService, ReferenceAttributesService,
                  OrganizationService, SystemSettingsResource, TourService, uiGridConstants, UrlRetrievalService, UserDataModelResource,
                  UserPreferencesResource, UsersGroupService, UsersService, WatchlistService, UpsellingAdsService) {

        migrateLocalStorage();

        $rootScope.cssLoaded = false;
        var defaultUiConfig = {
            title: 'BYRD',
            favIcon: 'favicon.ico',
            appleTouchFavIcon: 'apple-touch-icon.png'
        };

        // feature for disabling backspace navigation (see LAX-3421)
        $document.bind("keydown keypress", function(evt) {
            if (evt.key === 8) {
                var allowed = /INPUT|SELECT|TEXTAREA/i;
                if (!allowed.test(evt.target.tagName) || evt.target.disabled || evt.target.readOnly) {
                    evt.preventDefault();
                }
            }
        });

        function getSiteUrl(s) {
            return lax_rest_url_complete('site/' + s);
        }

        function isCustomSite() {

            // Check if hostname is a custom site,
            // i.e. it is a "valid" hostname and differs from all possible hostnames
            var hostname = hostname || window.location.hostname;
            if (hostname === __laxHostname__ || !(hostname.indexOf('.') > 0 && /^[a-zA-Z]/.test(hostname))) {
                // Hostname matches or is not valid, so it cannot be a customSite
                return false;
            }

            // Find a matching domain for the defined hostname.
            // If found, check the current hostname against all possible hostnames
            var matchingDomain = _.find(__laxDomains__, function(domain) {
                if (_.endsWith(__laxHostname__, '.' + domain)) {
                    return true;
                }
            });
            if (!_.isEmpty(matchingDomain)) {

                matchingDomain = '.' + matchingDomain;
                var laxHostnamePrefix = __laxHostname__.slice(0, -(matchingDomain.length));
                var matches = _.some(__laxDomains__, function(domain) {
                    return (hostname === (laxHostnamePrefix + '.' + domain));
                });

                // Current hostname matches a possible hostname, so it is not a customSite
                return !matches;
            }

            return true;
        }

        // FIXME: custom site configurations should have their own service file.
        var CUSTOM_SITE_DESIGN = 'custom-site-css';
        var customUiConfig = {};

        $rootScope.uiConfig = function(key) {
            var customValue = customUiConfig[key];
            if (customValue !== undefined) {
                return customValue;
            } else {
                return DefaultUiConfig[key];
            }
        };

        $rootScope.uiConfigUrl = function(key) {
            var customValue = customUiConfig[key];
            if (customValue !== undefined) {
                if (!_.startsWith(_.toLower(customValue), 'http:') && !_.startsWith(_.toLower(customValue), 'https:')) {
                    if (!_.startsWith(customValue, '/')) {
                        customValue = getSiteUrl(customValue);
                    } else {
                        customValue = window.location.protocol + "//" + window.location.host + customValue;
                    }
                }
                return customValue;
            } else {
                return DefaultUiConfig[key];
            }
        };

        function loadCustomUiConfig(overrideDesign) {

            var url = getSiteUrl('custom.json?emptyIfNotFound=true');
            $http.get(url).then(function(response) {

                var design, designPath;

                var addCustomCss;
                var customCssPath = getSiteUrl('custom.css?emptyIfNotFound=true');

                if (!_.isEmpty(response.data)) {

                    for (var key in response.data) {
                        customUiConfig[key] = response.data[key];
                    }

                    design = customUiConfig.design;
                    if (_.isEmpty(design)) {
                        if (customUiConfig.use_default_design != true) {
                            design = window.location.host + '/customSiteCss';
                            designPath = customCssPath;
                        } else {
                            addCustomCss = true;
                        }
                    }

                } else {
                    customUiConfig = {};
                }

                $rootScope.disableSignUpBtn = customUiConfig.disable_signup;

                if (overrideDesign) {
                    design = overrideDesign;
                    designPath = null;
                } else {
                    design = design || $rootScope.systemSettings.NEW_ORGANIZATION_DEFAULT_DESIGN;
                }

                if (_.isEmpty(design)) {
                    $rootScope.cssLoaded = true;
                } else {
                    $rootScope.setDesign(design, designPath).then(function() {
                        if (addCustomCss) {
                            setCustomCss(CUSTOM_SITE_DESIGN, customCssPath);
                        }
                    });
                }

                var favIconElement = angular.element('link[rel~="icon"]');
                var appleTouchIcon = angular.element('link[rel~="apple-touch-icon-precomposed"]');
                if (!_.isNil(customUiConfig.fav_icon) && !_.isEmpty(favIconElement) && !_.isEmpty(appleTouchIcon)) {
                    favIconElement[0].href = customUiConfig.fav_icon;
                    appleTouchIcon[0].href = customUiConfig.fav_icon;
                } else {
                    favIconElement[0].href = defaultUiConfig.favIcon;
                    appleTouchIcon[0].href = defaultUiConfig.appleTouchFavIcon;
                }

                var titleElement = angular.element('title');
                if (!_.isNil(customUiConfig.page_title) && !_.isEmpty(titleElement)) {
                    titleElement[0].innerText = customUiConfig.page_title;
                } else {
                    titleElement[0].innerText = defaultUiConfig.title;
                }

                if (!_.isNil(customUiConfig.disable_tours)) {
                    $rootScope.disableTours = true;
                }

                if (!_.isNil(customUiConfig.support_email)) {
                    $rootScope.customSiteSupportEmail = customUiConfig.support_email;
                }

                if (!_.isNil(customUiConfig.terms_of_service)) {
                    $rootScope.customSiteTermsOfService = customUiConfig.terms_of_service;
                }

                if (!_.isNil(customUiConfig.terms_of_service_text)) {
                    $rootScope.customSiteTermsOfServiceText = customUiConfig.terms_of_service_text;
                }

                if (!_.isEmpty(customUiConfig.additional_user_settings_menu_entries)) {
                    $rootScope.additionalUserSettingsMenuEntries = customUiConfig.additional_user_settings_menu_entries;
                }

                if (!_.isEmpty(customUiConfig.support_widget)) {
                    $rootScope.supportWidget = customUiConfig.support_widget;
                }

            });
        }

        function loadDefaultSiteUiConfig() {

            var favIconElement = angular.element('link[rel~="icon"]');
            var appleTouchIcon = angular.element('link[rel~="apple-touch-icon-precomposed"]');
            if (!_.isEmpty(favIconElement) && !_.isEmpty(appleTouchIcon)) {
                favIconElement[0].href = defaultUiConfig.favIcon;
                appleTouchIcon[0].href = defaultUiConfig.appleTouchFavIcon;
            }

            var titleElement = angular.element('title');
            if (!_.isEmpty(titleElement)) {
                titleElement[0].innerText = defaultUiConfig.title;
            }

        }

        $rootScope.systemSettings = {};

        $rootScope.systemSetting = function(key) {
            return systemSettings[key];
        };

        $rootScope.getTermsOfServiceUrl = function() {
            if (!_.isNil($rootScope.customSiteTermsOfService)) {
                return $rootScope.customSiteTermsOfService;
            } else {
                return $rootScope.systemSettings.TERMS_AND_CONDITIONS_URL;
            }
        };

        $rootScope.getLoginTermsOfServiceText = function() {
            // FIXME: We should rethink how to override the translations defined in custom site configurations, just like
            // what we do in 'translationLoader' for data models, the following could be of help
            // -> https://github.com/angular-translate/angular-translate/issues/1125#issuecomment-122667786
            var customSiteText = $rootScope.customSiteTermsOfServiceText;
            if (!_.isNil(customSiteText) &&
                !_.isNil(customSiteText[$rootScope.language])) {
                var languageText = customSiteText[$rootScope.language];
                languageText = languageText.replace('{{url}}', $rootScope.getTermsOfServiceUrl());
                return $sce.trustAsHtml(languageText);
            } else {
                var translation = $translate.instant('TERMS_AND_CONDITIONS.LOGIN', {
                    url: $rootScope.getTermsOfServiceUrl()
                });
                return $sce.trustAsHtml(translation);
            }
        };

        $rootScope.initializeSettings = function () {
           return SystemSettingsResource.get({}, function(response) {

                var ads = response.ads || [];
                var systemSettings = response.systemSettings;

                angular.forEach(systemSettings, function(value, key) {
                    $rootScope.systemSettings[key] = value;
                });

                if (isCustomSite()) {
                    loadCustomUiConfig();
                } else if (!_.isEmpty($rootScope.systemSettings.NEW_ORGANIZATION_DEFAULT_DESIGN)){
                    loadDefaultSiteUiConfig();
                    $rootScope.setDesign($rootScope.systemSettings.NEW_ORGANIZATION_DEFAULT_DESIGN);
                } else {
                    loadDefaultSiteUiConfig();
                    $rootScope.cssLoaded = true;
                }
                //to test the upselling ads locally uncomment [263-266]
                // $http.get('http://localhost:3000/fetch-upselling-ads?marketingTargets=HCDP').then(function (res){
                //     var eligibleAds = UpsellingAdsService.getEligibleAds(res.data || res);
                //     $rootScope.upsellingAd = UpsellingAdsService.pickRandomAd(eligibleAds);
                // });

                if (ads.length > 0) {
                    var eligibleAds = UpsellingAdsService.getEligibleAds(ads);
                    $rootScope.upsellingAd = UpsellingAdsService.pickRandomAd(eligibleAds);
                }

            });
        };
        $rootScope.initializeSettings();

        $rootScope.setDesign = function(design, designPath) {

            var deferred = $q.defer();

            if (design == $rootScope.currentDesign) {
                deferred.reject();
            } else if (!_.isEmpty(design) && document.getElementById(design)) {
                $rootScope.currentDesign = designPath;
                deferred.reject();
            } else if (!_.isEmpty(design)) {

                if (_.isEmpty(designPath)) {
                    if (!_.startsWith(design, '/')) {
                        designPath = '/designs/' + design + '.css';
                    } else if (_.startsWith(design, '/web/')) {
                        if (_.endsWith(design, '.css')) {
                            designPath = design + '?emptyIfNotFound=true';
                        } else if (!_.endsWith(design, '.css?emptyIfNotFound=true')) {
                            designPath = design + '.css?emptyIfNotFound=true';
                        } else {
                            designPath = design;
                        }
                    } else {
                        designPath = design;
                    }
                }

                $http.get(designPath).then(function() {
                    unsetCustomCss(CUSTOM_SITE_DESIGN);
                    unsetCustomCss($rootScope.currentDesign);
                    setCustomCss(design, designPath);
                    $rootScope.currentDesign = design;
                    deferred.resolve();
                }, function(errorResponse) {
                    $log.error("Could not load design '" + design + "' from path '" + designPath + "': " + errorResponse.statusText);
                    $rootScope.cssLoaded = true;
                });

            } else {
                deferred.reject();
            }

            return deferred.promise;
        };

        function setCustomCss(name, url) {

            var node = document.createElement('link');
            node.id = name;
            node.setAttribute('rel', 'stylesheet');
            node.setAttribute('type', 'text/css');
            node.setAttribute('href', url);
            document.getElementsByTagName('head')[0].appendChild(node);

            node.onload = function() {
                $rootScope.cssLoaded = true;
                $rootScope.$digest();
            };

        }

        function unsetCustomCss(name) {
            if (!_.isEmpty(name)) {
                var linkNode = document.getElementById(name);
                if (linkNode) {
                    linkNode.parentNode.removeChild(linkNode);
                }
            }
        }

        $rootScope.handleSSOResponse = function(response) {

            // Ensure, response was received from our own origin
            var origin = window.location.origin;
            var responseUrl = new URL(response.config.url, origin);
            if (responseUrl.origin !== origin) {
                $rootScope.singleSignOn = false;
                return $rootScope.singleSignOn;
            }

            // Check if and which type of sso is returned
            var ssoRedirect = response.headers(HttpHeader.SSO_REDIRECT);
            var ssoPost = response.headers(HttpHeader.SSO_POST);
            if (ssoRedirect) {

                // Manually redirect, because a "normal" redirect response somehow does not work correctly in AngularJS
                var ssoRedirectURI = response.data;
                $log.info("Got a SSO redirect response");
                $log.debug("SSO redirection URI:", ssoRedirectURI);

            } else if (ssoPost) {

                // Show html post form from params
                var ssoPostParams = response.data;
                $log.info("Go a SSO POST response");
                $log.debug("SSO post params:", ssoPostParams);

            } else {
                $rootScope.singleSignOn = false;
                return $rootScope.singleSignOn;
            }

            $cookies.remove(AppConstants.AUTHENTICATION_TOKEN);
            $rootScope.singleSignOn = true;

            var msg = $rootScope.loggingOut ? 'SSO.LOGOUT_REDIRECT' : 'SSO.LOGIN_REDIRECT';

            $modal.open({
                template: '<div class="modal-body text-center" ><span data-translate>' + msg + '</span></div>',
            }).opened.then(function() {
                $timeout(function() {
                    if (!_.isEmpty(ssoRedirectURI)) {

                        // Manually redirect to URI
                        window.location = ssoRedirectURI;

                    } else if (!_.isEmpty(ssoPostParams)) {

                        // Show html post form from params
                        /* jshint evil:true */
                        document.open();
                        document.write(ssoPostParams.formHtml);
                        document.close();
                        /* jshint evil:false */

                    }
                }, 2000);
            });

            return $rootScope.singleSignOn;
        };

        var DATA_MODEL_TAG_KEY = "dataModelTag";
        var DATA_MODEL_MAP_KEY = "dataModelMap";

        var USER_DATA_MODEL_TAG_KEY = "userDataModelTag";
        var USER_DATA_MODEL_MAP_KEY = "userDataModelMap";

        $rootScope._ = _;
        $rootScope.disableSignUpBtn = false;
        $rootScope.referencedItems = [];
        $rootScope.bulkSearchLoading = false;
        $rootScope.bulkSearchStillRunning = false;
        $rootScope.bulkSearch = false;
        $rootScope.escapeSearchTerm = function(term) {
            //all colons need to be escaped in the search Term:
            if (term === undefined) return undefined;
            return term.replace(/:\s*/g, '\\:');
        };

        $rootScope.getErrorCode = function(errorResponse) {
            var errorCode = _.get(errorResponse, 'data.errorCode') || ErrorCode.UNSPECIFIED;
            return errorCode;
        };

        $rootScope.getErrorMessage = function(errorResponse, defaultMessage) {
            var errorMessage = _.get(errorResponse, 'data.message');
            if (errorMessage) {
                var errorParams = _.get(errorResponse, 'data.parameters');
                errorMessage = $rootScope.translate(errorMessage, errorMessage, errorParams);
            } else {
                errorMessage = defaultMessage || errorResponse.data;
            }
            return errorMessage;
        };

        $rootScope.getErrorParameter = function(errorResponse, paramName) {
            return _.get(errorResponse, 'data.parameters' + '.' + paramName);
        };

        $rootScope.getOptions = function ($scope, attributeDefinition, memberAttributes, data, config, modelString) {

            config = config || {};
            var editable = (config.editable === true);
            var addRemoveButton = (config.removeButton ? true : false);
            var columnDefs = [];

            // The following attributes `enableCellEdit` state should be disabled in a grid
            // due to their nature, but that doesn't mean that all of them will be readonly, as they
            // define their own editing behavior using the view template of the column.
            var NO_EDIT_TEMPLATES_RENDERERS = [
                'AdditionalCategory',
                'SingleBoolean',
                'Document',
                'Image'
            ];
            var READONLY_COMPOSITE_ATTRIBUTES = [ 'MultiReference' ];

            /*
             * We need to check the attribute readonly state everytime
             * we try to edit a cell, in case the attribute readonly state
             * has changed via a validation attribute states response.
            */
            var cellEditableConditionFn = function cellEditableConditionFn ($scope, triggerEvent) {

                if (editable && !_.isNil($scope.col.colDef.attribute)) {

                    var attribute = $scope.col.colDef.attribute;
                    var attributeRenderer = getAttributeRenderer(attribute);
                    var rendererType = attributeRenderer.type;

                    var ofRendererWithNoEditTemplate = _.includes(NO_EDIT_TEMPLATES_RENDERERS, rendererType);
                    var readonlyByAttributeStates = $scope.col.grid.appScope.isAttributeReadonly(attribute);

                    var readonlyByCompositeAttribute = false;
                    if (READONLY_COMPOSITE_ATTRIBUTES.contains(attributeDefinition.typeName)) {

                        if ($rootScope.dataModel.isMetaAttribute(attribute.name)) {
                            readonlyByCompositeAttribute = true;
                        } else {

                            // Some composite attributes has their own readonly logic, ex. MultiReference attributes
                            // only allow editing the transient attributes stored on the link.
                            var transientAttributes = ReferenceAttributesService.getTransientAttributes(attributeDefinition);
                            if (transientAttributes.contains(attribute.name)) {
                                readonlyByCompositeAttribute = false;
                            } else {
                                readonlyByCompositeAttribute = true;
                            }

                        }
                    }

                    if (!ofRendererWithNoEditTemplate &&
                        !readonlyByAttributeStates &&
                        !readonlyByCompositeAttribute) {
                        return true;
                    }
                }

                return false;
            };

            for (var i = 0; i < memberAttributes.length; i++) {

                var memberAttribute = $rootScope.dataModel.attribute(memberAttributes[i]);
                if (!_.isNil(memberAttribute)) {
                    var columnDef = {
                        attribute: memberAttribute,
                        field: memberAttribute.name,
                        headerTooltip: true,
                        cellEditableCondition: cellEditableConditionFn,
                        width: '*',
                        minWidth: 140
                    };

                    // Prepare column definition for sorting in case of any
                    _.assign(columnDef, $rootScope.getColumnSortingOptions(attributeDefinition, memberAttribute, i));

                    $rootScope.generateCellEditors(memberAttribute, columnDef, $scope, config);

                    $rootScope.extendColumnDefinition(attributeDefinition, columnDef);

                    $rootScope.generateCellHeader(memberAttribute, columnDef, attributeDefinition);

                    columnDefs.push(columnDef);
                } else {
                    $log.error('There was a missing member attribute ' + memberAttributes[i] + ' in the data model while preparing attribute ' + attributeDefinition.name + '.');
                }

            }

            if (addRemoveButton) {
                var cellTemplate = $rootScope.generateActionsColumnDefinition(attributeDefinition);

                columnDefs.push({
                    field: 'remove',
                    width: 50,
                    displayName: '',
                    cellClass: 'text-right',
                    cellTemplate: cellTemplate,
                    enableCellEdit: false,
                    pinnedRight: true,
                    enableHiding: false
                });
            }
            return {
                data: modelString,
                enableHighlighting: true,
                enableColumnResize: true,
                enableColumnMenus: false,
                enableRowHeaderSelection: false,
                enableGridMenu: true,
                multiSelect: false,
                columnDefs: columnDefs,
                rowHeight: 34,
                enableHorizontalScrollbar: 1, // hide
                enableVerticalScrollbar: 1, // show when needed
                onRegisterApi: function(gridApi) {

                    if (attributeDefinition.typeName === 'MultiDimensional') {
                        gridApi.core.on.rowsRendered($scope, function() {

                            // Check every row errors and add them to the list of
                            // local validations so we can prevent the user from saving
                            // the item while the MultiDimensional attribute is invalid.
                            var rowValidationErrors = [];
                            _.forEach(gridApi.grid.rows, function(row) {
                                row.isInvalid = !MultiDimensionalAttributeService.validateMultiDimensionalEntry(row,
                                    gridApi.grid.rows,
                                    attributeDefinition);

                                if (row.isInvalid) {
                                    rowValidationErrors.push(row.errors);
                                }
                            });

                            gridApi.grid.appScope.$emit('addLocalValidation', {
                                path: modelString,
                                attributeErrors: {
                                    attribute: attributeDefinition,
                                    errors: _.uniq(rowValidationErrors)
                                }
                            });
                        });
                    }
                }
            };
        };

        // Default sorting algorithms by base class
        // Needs to be set, because attribute values are only stored as String!
        var defaultSorters = {
            'Integer': function(a, b) { return rowSorter.sortNumberStr(a, b); },
            'Float': function(a, b) { return rowSorter.sortNumberStr(a, b); },
            'Boolean': function(a, b) { return rowSorter.sortBool(a, b); },
            'Date': function(a, b) { return rowSorter.sortDate(a, b); },
            'DateTime': function(a, b) { return rowSorter.sortDate(a, b); },
        };

        $rootScope.getColumnSortingOptions = function(attributeDefinition, memberAttribute, indexOfMemberAttribute) {

            var sortingOptions = _.find(attributeDefinition.params.uiCollectionSorter, function(attributeSortingOptions) {
                return attributeSortingOptions.key === memberAttribute.name;
            });

            if (!_.isNil(sortingOptions)) {

                var sortingAlgorithm = sortingOptions.value.sortingAlgorithm;
                var sortingFunction = $rootScope.getService(sortingAlgorithm);
                if (_.isNil(sortingFunction)) {
                    $log.warn("Sorting function '" + sortingAlgorithm +
                              "' is defined in attribute '" + attributeDefinition.name +
                              "' for member attribute '" + memberAttribute.name +
                              "' but was not found!'");
                } else {

                    var user = $rootScope.user;
                    var organization = OrganizationService.getOrganizationSnapshot();

                    return {
                        sort: {
                            priority: sortingOptions.value.sortPriority || indexOfMemberAttribute,
                            direction: sortingOptions.value.initialDirection || uiGridConstants.ASC
                        },
                        sortDirectionCycle: sortingOptions.value.sortDirectionCycle || undefined,
                        suppressRemoveSort: sortingOptions.value.suppressRemoveSort || false,
                        sortingAlgorithm: prepareSortingAlgorithm(memberAttribute, attributeDefinition, sortingFunction, user, organization)
                    };
                }

            }

            // Define a default sorter for specific base classes
            var sorter = defaultSorters[memberAttribute.baseClass];
            if (_.isFunction(sorter)) {
                return {
                    sortingAlgorithm: sorter
                };
            } else {
                return {};
            }
        };

        function prepareSortingAlgorithm(memberAttribute, parentAttribute, sortingFunction, user, organization) {
            return function(a, b, rowA, rowB, direction) {
                return sortingFunction(a, b, rowA.entity, rowB.entity, direction, memberAttribute, parentAttribute, user, organization);
            };
        }

        function getAttributeRenderer(attribute) {
            // Return the renderer and it's additional data for a given attribute.
            //
            // Falls back to the string representation or attribute type if no renderer
            // was set in the data model.
            //
            // examples for return values:
            //  {type: 'String'} or {type: 'TextArea', rows: 10}
            var renderer = null;
            var rendererParams;
            if (attribute.params) {
                renderer = attribute.params.customRenderer || attribute.params.renderer;
                if (angular.isObject(renderer)) {
                    rendererParams = {};
                    angular.forEach(renderer, function(entry) {
                        rendererParams[entry.key] = entry.value;
                    });
                    renderer = rendererParams;
                } else if (angular.isString(renderer)) {
                    renderer = {
                        type: renderer
                    };
                }
            }

            // fallback if no valid renderer is defined
            renderer = renderer || {};
            if (!renderer.type) {
                renderer.type = attribute.typeName || attribute.baseClass;
            }

            return renderer;
        }

        $rootScope.generateCellHeader = function(memberAttribute, columnDefinition, attribute) {

            if (attribute.typeName === 'MultiDimensional' &&
                MultiDimensionalAttributeService.isKeyAttribute(memberAttribute, attribute)) {
                // For MultiDimensional, if the header represents a key attribute member, we mark it with a special icon.
                columnDefinition.headerCellClass = 'header-primary-attribute-icon';
            }
        };

        $rootScope.extendColumnDefinition = function(attributeDefinition, columnDefinition) {

            if (attributeDefinition.typeName === 'MultiDimensional') {
                columnDefinition.cellClass = function(grid, row, col, rowRenderIndex, colRenderIndex) {
                    var isRowValid = MultiDimensionalAttributeService.validateMultiDimensionalEntry(row, grid.rows, attributeDefinition);
                    if (!isRowValid) {
                        return 'ui-grid-invalid-cell';
                    } else {
                        return '';
                    }
                };
            } else if (attributeDefinition.typeName === 'MultiReference') {
                columnDefinition.cellClass = function(grid, row, col, rowRenderIndex, colRenderIndex) {
                    if (row.entity.deleted__) {
                        return 'ui-grid-invalid-cell';
                    } else {
                        return '';
                    }
                };
            }
        };

        $rootScope.generateActionsColumnDefinition = function(attributeDefinition) {

            var prefix = '<div class="flex h-full items-center justify-even">';
            var suffix = '</div>';
            var removeButtonTemplate =
                '<button type="button" class="btn btn-cell p-0" ' +
                '  tabindex="-1" ' +
                '  data-ng-disabled="dataModel.sectionAttributeParam(currentLayout, undefined, section.name, a.name, \'readonly\')" ' +
                '  data-ng-click="grid.appScope.removeRow(row, grid.appScope.a.name)">' +
                '  <span class="syncons syncons-delete"></span>' +
                '</button>';

            var additionalButtons = '';
            if (attributeDefinition.typeName === 'MultiDimensional') {
                additionalButtons = '<hint-tooltip data-hint-id="\'' + attributeDefinition.name + '-\'' + ' + row.index" data-hint-content="row.errors" data-show-hint-if="row.isInvalid" data-hint-icon="\'syncons syncons-error\'"></hint-tooltip>';
            } else if (attributeDefinition.typeName === 'MultiReference') {
                var errorMessage = $translate.instant('MULTI_REFERENCE.REFERENCED_ITEM_NOT_FOUND');
                additionalButtons = '<hint-tooltip data-hint-id="\'' + attributeDefinition.name + '-\'' + ' + row.index" data-hint-content="\'' + errorMessage + '\'" data-show-hint-if="row.entity.deleted__" data-hint-icon="\'syncons syncons-error\'"></hint-tooltip>';
            }

            return prefix + additionalButtons + removeButtonTemplate + suffix;
        };

        $rootScope.generateCellEditors = function(attribute, columnDefinition, scope, config) {

            $rootScope.prepareAttribute(attribute);
            columnDefinition.displayName = attribute.translatedLabel;

            config = config || {};

            var context = (config.context || 'grid');
            var emptyDefault = (config.emptyDefault || false);
            var readOnly = (config.editable === false) || attribute.readOnly;
            var cellId = "cell-{{grid.renderContainers.body.visibleRowCache.indexOf(row) + '-' + grid.renderContainers.body.visibleColumnCache.indexOf(col)}}";
            var renderer = getAttributeRenderer(attribute);
            var attributeName = attribute.name + "_{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}";

            columnDefinition.cellId = cellId;
            columnDefinition.attributeName = attributeName;
            columnDefinition.readonly = readOnly;
            var cellTemplate = $templateCache.get('Grid-' + renderer.type);
            if (cellTemplate) {
                columnDefinition.renderer = renderer;
                // FIXME: 'getAttributeOptions()' has unexpected behavior with dynamically loaded
                // options, use 'EnumAttributeService.getAttributesAsync()' instead.
                columnDefinition.options = $rootScope.getAttributeOptions(attribute, true);
                columnDefinition.cellTemplate = cellTemplate;

                // Set editable custom template, if it exists
                // Readonly state will be determined at runtime (post-render of the grid), but
                // we should always provide the edit template in case the readonly state
                // changes by other item changes.
                var editableCellTemplate = $templateCache.get('Grid-Editable-' + renderer.type);
                if (editableCellTemplate) {
                    columnDefinition.editableCellTemplate = editableCellTemplate;
                }
            } else if (renderer.type === "AdditionalCategory") {
                generateCellEditorForAdditionalCategoryType(columnDefinition, readOnly, cellId);
            } else if (renderer.type === "Boolean" ||
                       renderer.type === "Enum" ||
                       renderer.type === "OpenEnum" ||
                       renderer.type === "Codelist" ||
                       renderer.type === "OpenCodelist") {
                generateCellEditorForAnyEnumType(columnDefinition, readOnly, cellId, scope, attribute, attributeName);
            } else if (renderer.type === "EnumSet" ||
                       renderer.type === "OpenEnumSet" ||
                       renderer.type === "CodelistSet" ||
                       renderer.type === "OpenCodelistSet") {
                generateCellEditorForEnumSet(columnDefinition, readOnly, cellId, scope, attribute, attributeName);
            } else if (renderer.type ==="SingleBoolean") {
                generateCellEditorForSingleBooleanType(columnDefinition, readOnly, cellId, scope, attribute, attributeName);
            } else if (renderer.type === "Date") {
                generateCellEditorForDateType(columnDefinition, readOnly, cellId, scope.getDateFormat(attribute), attributeName);
            } else if (renderer.type === "DateTime") {
                generateCellEditorForDateTimeType(columnDefinition, readOnly, cellId, scope.getDateFormat(attribute), attributeName);
            } else if (renderer.type === "Dimensional" || renderer.type === "MultiLineDimensional") {
                generateCellEditorForDimensional(columnDefinition, readOnly, cellId, attributeName);
            } else if (renderer.type === "Document" || renderer.type === "Image") {
                generateCellEditorForDocumentOrImageType(columnDefinition, readOnly, cellId);
            } else if (renderer.type === "Float") {
                generateCellEditorForFloatType(columnDefinition, readOnly, cellId, attributeName);
            } else if (renderer.type === "Physical") {
                generateCellEditorForPhysicalType(columnDefinition, readOnly, cellId, context, attribute, attributeName, scope);
            } else if (renderer.type === "TextArea") {
                generateCellEditorForTextAreaType(columnDefinition, readOnly, cellId, renderer.rows);
            } else {
                generateDefaultCellEditor(columnDefinition, readOnly, cellId, attribute, attributeName);
            }

            // default cellTemplate
            if (attribute.name === "category__") {
                columnDefinition.cellTemplate = columnDefinition.cellTemplate ? columnDefinition.cellTemplate : '{{COL_FIELD | categoryNameToLabel}}';
            } else {
                columnDefinition.cellTemplate = columnDefinition.cellTemplate ? columnDefinition.cellTemplate : '{{COL_FIELD CUSTOM_FILTERS}}';
            }

            var cellTemplatePrefix;
            var cellTemplateSuffix;
            if (context === "browse" || context === "subscription") {
                cellTemplatePrefix =
                    '<div id="' + cellId + '" class="' + attributeName + '-cell ui-grid-cell-contents" title="TOOLTIP" ' +
                    // We use `ng-hide` to prevent creating more $scopes and more grid $watchers
                    '  data-ng-hide="grid.appScope.isGridAttributeHidden(row, col)" ' +
                    '  data-ng-class="{\'has-error\': grid.appScope.isCellInvalid(row, col), \'bg-color-light-green\': row.entity.audited__}">';
                cellTemplateSuffix = '</div>';
            } else {
                cellTemplatePrefix = '<div id="' + cellId + '" class="' + attributeName + '-cell ui-grid-cell-contents">';
                cellTemplateSuffix = '</div>';
            }

            columnDefinition.cellTemplate = cellTemplatePrefix + columnDefinition.cellTemplate + cellTemplateSuffix;

        };

        function generateCellEditorForAdditionalCategoryType(colDef, readOnly, cellId) {
            colDef.cellTemplate =
                '<span id="category-' + cellId + '"\n' +
                '      data-ng-init="grid.appScope.setGridAdditionalCategory(this,  \'row.entity.\' + col.field, \'additionalCategory\', col.colDef.attribute)"\n' +
                '      data-ng-bind="additionalCategory.formattedLabel"\n>' +
                '</span>';
            colDef.enableCellEdit = false;
        }

        function generateCellEditorForAnyEnumType(colDef, readOnly, cellId, scope, attribute, attributeName) {

            colDef.getOptionsPromise = EnumAttributeService.getAttributeOptionsAsync(attribute, true);
            colDef.cellTemplate = $templateCache.get('Grid-EnumType');

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate = $templateCache.get('Grid-Editable-EnumType');
        }

        function generateCellEditorForEnumSet(colDef, readOnly, cellId, scope, attribute, attributeName) {

            EnumAttributeService.getAttributeOptionsAsync(attribute, true)
                .then(function(options) {
                    colDef.options = options;
                });
            colDef.cellFilter = "gridEnumSetFormatter:col.colDef.options";

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate = $templateCache.get('Grid-Editable-EnumSetType');
        }

        function generateCellEditorForSingleBooleanType(colDef, readOnly, cellId, scope, attribute, attributeName) {
            // FIXME: 'getAttributeOptions()' has unexpected behavior with dynamically loaded
            // options, use 'EnumAttributeService.getAttributesAsync()' instead.
            colDef.options = $rootScope.getAttributeOptions(attribute, true);
            colDef.cellTemplate = $templateCache.get('Grid-SingleBoolean');
            colDef.enableCellEdit = false;
        }

        function generateCellEditorForDateType(colDef, readOnly, cellId, format, attributeName) {
            colDef.cellFilter = "date:'" + format + "'";
            colDef.minWidth = 150;

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate =
                '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell">' +
                '  <form name="inputForm">' +
                '    <input id="input_' + cellId + '" class="form-control ' + attributeName + '-input-cell" type="text" ' +
                '      data-ng-model="MODEL_COL_FIELD" ' +
                '      data-lax-datepicker-grid ' +
                '      data-lax-datepicker="' + format + '" />' +
                '  </form>' +
                '</div>';
        }

        function generateCellEditorForDateTimeType(colDef, readOnly, cellId, format, attributeName) {
            colDef.cellFilter = "date:'" + format + "'";
            colDef.minWidth = 150;

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate =
                '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell">' +
                '  <form name="inputForm">' +
                '    <input id="input_' + cellId + '" class="form-control ' + attributeName + '-input-cell" type="text" ' +
                '      data-ng-model="MODEL_COL_FIELD" ' +
                '      data-lax-datepicker-grid ' +
                '      data-lax-datetime-picker="' + format + '" />' +
                '  </form>' +
                '</div>';
        }

        function generateCellEditorForDimensional(colDef, readOnly, cellId, attributeName) {
            colDef.cellTemplate =
                 '<div id="edit-' + cellId + '" ' +
                 '  data-ng-model="MODEL_COL_FIELD" >' +
                 '  {{grid.getCellValue(row, col)[grid.appScope.getFirstFilteredDimensionKey(row.entity, col.colDef.attribute)]}}' +
                 '</div>';

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate =
                '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell">' +
                '  <form name="inputForm">' +
                '    <input type="text" ' +
                '      class="form-control ' + attributeName + '-input-cell" ' +
                '      data-ng-model="MODEL_COL_FIELD[grid.appScope.getFirstFilteredDimensionKey(row.entity, col.colDef.attribute)]" ' +
                '      lax-grid-input>' +
                '  </form>' +
                '</div>';
        }

        function generateCellEditorForDocumentOrImageType(colDef, readOnly, cellId) {
            colDef.cellTemplate =
                '<button type="button" class="btn btn-xs btn-cell center-block"\n' +
                '  tabindex="-1"\n' +
                '  data-ng-click="showGridFileDialog(row, col, grid.appScope)"\n' +
                '  data-grid-file-dialog\n' +
                '  data-url="row.entity[col.field]" data-row="row" data-col="col">\n' +
                '  <div data-ng-if="row.entity[col.field] && !row.filePreviewError[col.field]">\n' +
                '     <file-thumbnail data-url="row.entity[col.field]" data-img-class="itemImage-small">\n' +
                '     </file-thumbnail>\n' +
                '  </div>\n' +
                '  <span data-ng-if="!row.entity[col.field]"><i class="syncons syncons-upload"></i></span>\n' +
                '  <span data-ng-if="row.entity[col.field] && row.filePreviewError[col.field]">\n' +
                '     <i class="syncons syncons-error attribute-preview-error"></i>\n' +
                '     <span data-translate>FILE_NOT_FOUND</span>\n' +
                '  </span>\n' +
                '</button>';
            colDef.enableCellEdit = false;
        }

        function generateCellEditorForFloatType(colDef, readOnly, cellId, attributeName) {
            colDef.cellFilter = "formatFloatValue";

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate =
                '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell">' +
                '  <form name="inputForm">' +
                '    <input type="text" ' +
                '      class="form-control ' + attributeName + '-input-cell" ' +
                '      data-ng-model="MODEL_COL_FIELD" ' +
                '      data-float-formatter ' +
                '      lax-grid-input>' +
                '  </form>' +
                '</div>';
        }

        function generateCellEditorForPhysicalType(colDef, readOnly, cellId, context, attribute, attributeName, scope) {
            colDef.cellFilter = 'formatFloatValue:this | formatPhysicalValue:col.colDef.attribute';

            // Dynamically load the physical attribute options, whether it's in the browse layout (item is the row), or it's
            // in the editor (item is in the editor scope).
            colDef.getOptions = function(gridScope) {
                var options = $rootScope.getTranslatedOptions(attribute);
                var item = $rootScope.getItemInContext(context, scope, gridScope);

                return EnumAttributeService.filterOptions(item, gridScope.col.colDef.attribute, null, null, options);
            };

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            colDef.editableCellTemplate =
                '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell">' +
                '  <form name="inputForm">' +
                '    <input id="input_' + cellId + '" type="text" class="form-control ' + attributeName + '-input-cell" ' +
                '      data-ng-model="MODEL_COL_FIELD" ' +
                '      autocomplete="off" ' +
                '      data-lax-grid-input="laxTypeaheadClosed" ' +
                '      data-physical-attribute="col.colDef.attribute" ' +
                '      data-units="col.colDef.getOptions(this)" ' +
                '      data-float-formatter ' +
                '      data-typeahead="unit.key as unit.value for unit in col.colDef.getOptions(this) | filterPhysicalValues:$viewValue" ' +
                '      data-typeahead-append-to-body="true" ' +
                '      data-typeahead-on-select="typeaheadSelected(\'laxTypeaheadClosed\')" />' +
                '  </form>' +
                '</div>';
        }

        function generateCellEditorForTextAreaType(colDef, readOnly, cellId, rows) {
            rows = rows || 1;

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            var editableCellTemplate =
                '<textarea id="textarea_"' + cellId + ' rows="' + rows + '" ' +
                '  data-ng-class="\'colt\' + grid.renderContainers.body.visibleColumnCache.indexOf(col)" ' +
                '  data-ng-model="MODEL_COL_FIELD" ' +
                '  data-lax-grid-input>' +
                '</textarea>';
            colDef.editableCellTemplate = editableCellTemplate;
        }

        function generateDefaultCellEditor(colDef, readOnly, cellId, attribute, attributeName) {
            // TODO: we should check with Modernizr if attribute type is supported by browser
            // e.g.: type = (Modernizr.inputtypes[INPUT_TYPE] ? INPUT_TYPE : 'text');
            // for now it's set to 'text' (=string) as default
            var type = 'text';

            // Readonly state will be determined at runtime (post-render of the grid), but
            // we should always provide the edit template in case the readonly state
            // changes by other item changes.
            if (attribute.params && attribute.params.uiShowLength) {
                colDef.editableCellTemplate =
                    '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell input-group input-group-sm string-length-group">' +
                    '    <input type="' + type + '" ' +
                    '      class="form-control ' + attributeName + '-input-cell" ' +
                    '      data-ng-model="MODEL_COL_FIELD" ' +
                    '      lax-grid-input>' +
                    '   <span id="string-length" class="input-group-addon">{{grid.appScope.getStringLengthMessage(MODEL_COL_FIELD.length, col.colDef.attribute.params.length)}}</span>' +
                    '</div>';
            } else {
                colDef.editableCellTemplate =
                '<div id="edit-' + cellId + '" class="' + attributeName + '-edit-cell">' +
                '  <form name="inputForm">' +
                '    <input type="' + type + '" ' +
                '      class="form-control ' + attributeName + '-input-cell" ' +
                '      data-ng-model="MODEL_COL_FIELD" ' +
                '      lax-grid-input>' +
                '  </form>' +
                '</div>';
            }
        }

        $rootScope.isRowEditable = function(row) {
            if (_.isUndefined(row.editable)) {
                row.editable = $rootScope.hasItemPermission('edit', row.entity);
            }
            return row.editable;
        };

        $rootScope.isGridAttributeHidden = function(row, col) {

            var item = row.entity;
            var attributeStates = item.attributeStates__;

            if (!_.isEmpty(attributeStates) && attributeStates.contains((col.name + ":hidden"))) {
                return true;
            }

            return false;
        };

        $rootScope.hasItemPermission = function(action, item) {
            return Auth.hasItemPermission(action, item);
        };

        $rootScope.showVal = function(object) {
            $log.info("showVal: object=", object);
        };

        $rootScope.scrollTo = function(id) {
            var old = $location.hash();
            $location.hash(id);
            $anchorScroll();
            // reset to old to prevent route changes
            $location.hash(old);
        };

        $rootScope.getFirstFilteredDimensionKey = function(item, attribute) {
            var filteredDimensions = $rootScope.getFilteredDimensions(item, attribute, item[attribute.name]);
            var firstDimension = _.head(filteredDimensions);
            return firstDimension ? firstDimension.key : null;
        };

        // FIXME: `setInputRenderer` should be moved to dataModel.js and merged with `datamodel.attribute()`.
        $rootScope.setInputRenderer = function(attribute) {
            // Set the template and extra render parameters for an attribute
            var renderer = getAttributeRenderer(attribute);
            attribute.template = renderer.type;
            if (!attribute.params) {
                attribute.params = {};
            }
            attribute.params.rendererParams = renderer;
        };

        $rootScope.getItemGridData = function(attributeDefinition, item, name, model, doFilter, scope) {

            if (_.isNil(item) || _.isNil(model)) {
                return;
            }

            var modelEval = $parse(model);
            var modelValue = modelEval(scope);

            var value = [];
            if (_.isNil(modelValue)) {
                if (!item.primaryKey__ && attributeDefinition.defaultValue) { // if new item => set defaultValues
                    value = angular.copy(attributeDefinition.defaultValue);
                }
            } else if (_.isString(modelValue) && modelValue.startsWith("[")) {
                value = JSON.parse(modelValue.toString());
            } else {
                value = modelValue;
            }

            if (doFilter) {
                value = $rootScope.filterCollection(value, item, attributeDefinition);
            }

            // Replace null values with empty objects to prevent ui-grid from complaining
            value = _.map(value, function(gridItem){
                if (!gridItem) {
                    return {};
                }
                return gridItem;
            });

            return value;
        };

        $rootScope.hasRole = function(role) {
            return !_.isNil($rootScope.user) &&
                (_.isNil($rootScope.user.roles) ||
                 _.isEmpty($rootScope.user.roles) ||
                 _.includes($rootScope.user.roles, role));
        };

        function hasOrganizationRole(role) {
            var organizationData = OrganizationService.getOrganizationSnapshot();
            var hasRole = organizationData !== null &&
                organizationData.organizationRole == role;
            return hasRole;
        }

        $rootScope.initOrganization = function() {

            var deferred = $q.defer();

            OrganizationService.getOrganization().subscribe(function(organization) {

                $rootScope.organization = angular.copy(organization);

                var design = _.get(organization, 'design');
                if (!_.isEmpty(_.get(organization, 'customSite')) || isCustomSite()) {
                    loadCustomUiConfig(design);
                } else {
                    customUiConfig = {};
                    loadDefaultSiteUiConfig();
                    $rootScope.setDesign(design);
                }

                deferred.resolve();
            });

            return deferred.promise;
        };

        $rootScope.initUsers = function() {
            UsersService.getUsers(true).subscribe(function(users) {
                $rootScope.users = angular.copy(users);
            });
        };

        $rootScope.hasRight = function(rights) {
            return Auth.hasRights(rights);
        };

        $rootScope.hasLicensedFeature = function(feature, organization) {
            var FEATURES_ALLOWED_FOR_COMPATIBILITY = ['TASKS'];
            organization = _.defaultTo(organization, $rootScope.organization);
            // Licensed features is new concept and not yet available to all organizations,
            // thus be default "old" organizations have all features!
            if (_.isNil(organization)) {
                return false;
            }
            if (_.isEmpty(organization.licensedFeatures)) {
                return _.includes(FEATURES_ALLOWED_FOR_COMPATIBILITY, feature);
            }
            var hasFeature = _.includes(organization.licensedFeatures, feature);
            return hasFeature;
        };

        $rootScope.hasSettingFeature = function(feature, organization) {
            organization = _.defaultTo(organization, $rootScope.organization);
            var value = _.get(organization, 'settings.FEATURE:' + feature);
            return value === true || value === 'true';
        };

        $rootScope.destroyAllNotifications = function() {
            var growlDOMContainer = angular.element('.growl-container');
            if (!_.isEmpty(growlDOMContainer)) {
                growlDOMContainer.scope().growlMessages.destroyAllMessages('0');
            }
        };

        $rootScope.inputTemplatesRegistered = false;

        $rootScope.activityStreamVisible = false;
        $rootScope.newActivities = 0;

        window.onunload = function(e) {
            $log.info("onunload:", e);
            ChannelService.disconnect();
        };

        $rootScope.initWhenLoggedIn = function() {
            ChannelService.connect(function() {
                ChannelService.registerMultiple([
                    ChannelService.BROADCAST_MESSAGE,
                    ChannelService.BULK_SEARCH_CHANGED_EVENT,
                    ChannelService.DATA_MODEL_ACTIVATEABLE_EVENT,
                    ChannelService.DATA_MODEL_CHANGED_EVENT,
                    ChannelService.DATA_MODEL_INVALID_EVENT,
                    ChannelService.DATA_MODEL_TEMPLATE_INVALID_EVENT,
                    ChannelService.EXPORT_SUMMARY_CHANGED_EVENT,
                    ChannelService.FINISHED_VIRUS_SCAN_ON_ASSET,
                    ChannelService.IMPORT_SUMMARY_CHANGED_EVENT,
                    ChannelService.MASS_UPDATE_SUMMARY_CHANGED_EVENT,
                    ChannelService.NEWS_STREAM_CHANGED_EVENT,
                    ChannelService.PUBLICATION_JOB_CHANGED_EVENT,
                    ChannelService.PUBLICATION_TASK_CHANGED_EVENT,
                    ChannelService.TASK_ASSIGNED_EVENT,
                    ChannelService.VALIDATION_FINISHED_EVENT,
                    ChannelService.VALIDATION_TASK_CHANGED_EVENT,
                    ChannelService.USERGROUPS_CHANGED
                ]);
                $rootScope.loadDataModel();
                if (Auth.hasRights('view.chat')) {
                    ChannelService.register(ChannelService.CHAT_EVENT, null, true);
                }
                $rootScope.initUsers();
            });
        };

        $rootScope.getDateFormat = function(attribute) {
            var format;
            if (attribute.typeName === "DateTime" || attribute.baseClass === "DateTime") {
                format = "medium";
            } else {
                format = 'mediumDate';
            }
//            // FIXME: Because of an error in our EU1169 data model the default format for date values is wrong!
//            // Thus we have to decide, if and when we will enable a fixed format again.
//            if (attribute && attribute.params && attribute.params['format']) {
//                format = attribute.params['format'];
//            }
            return format;
        };

        $rootScope.isFavorite = function(item) {
            return item.tags__ !== undefined && item.tags__ !== null && item.tags__.contains("favorite:" + $rootScope.userId);
        };

        $rootScope.normalizeToNilObject = function(object) {

            // FIXME: Who is actually using the service to override 'normalizeToNilObject'?
            var customService = $rootScope.getService('CustomService');

            return normalizeToNilObject(customService, object);
        };

        function normalizeToNilObject(customService, object) {

            _.forOwn(object, function(value, key) {

                // Ignore internal keys, e.g. __index__ inside a Group attribute
                if (_.startsWith(key, '__')) {
                    delete object[key];
                    return;
                }

                if (customService) {
                    _.invoke(customService, 'normalizeToNilObject', object, key, value);
                }

                if (_.isArray(value) || _.isPlainObject(value)) {
                    value = normalizeToNilObject(customService, value);
                } else {
                    value = normalizeToNil(value);
                }

                if (_.isNil(value) || (_.isObject(value) && _.isEmpty(value))) {
                    delete object[key];
                } else {
                    object[key] = value;
                }

            });

            _.remove(object, function(v) {
                return _.isNil(v);
            });

            return _.isEmpty(object) ? undefined : object;
        }

        function normalizeToNil(value) {

            if (_.isDate(value)) {
                value = value.getTime();
            } else if (_.isString(value) && value.length === 0) {
                value = undefined;
            } else if (_.isArray(value) || _.isPlainObject(value)) {
                value = $rootScope.normalizeToNilObject(value);
            } else if (_.isObject(value)) {
                value = _.toString(value);
            }

            return value;
        }

        function equalCustomizer(value1, value2) {

            value1 = normalizeToNil(value1);
            value2 = normalizeToNil(value2);

            if (_.isEqual(value1, value2)) {
                return true;
            } else if (_.isNil(value1) && _.isNil(value2)) {
                return true;
            } else if (_.isNil(value1) || _.isNil(value2)) {
                return false;
            } else if (_.isNumber(value1) || _.isNumber(value2)) {
                return _.isEqual(_.toNumber(value1), _.toNumber(value2));
            }

        }

        $rootScope.isEqual = function(value1, value2) {
            return _.isEqualWith(value1, value2, equalCustomizer);
        };

        $rootScope.isEmpty = function(value) {
            var empty;
            if (_.isNil(value)) {
                empty = true;
            } else if (_.isString(value)) {
                empty = (value === '');
            } else if (_.isDate(value)) {
                empty = false;
            } else if (_.isArray(value) || _.isPlainObject(value)) {
                empty = _.isEmpty(value);
            } else {
                empty = false;
            }
            return empty;
        };

        $rootScope.toString = function(value) {
            return JSON.stringify(value);
        };

        $rootScope.resetDataModel = function() {

            $rootScope.dataModel = new DataModel({
                attributes: {},
                categories: {},
                layouts: {}
            }, $log, $rootScope, $translate, $q, $window, Auth, DataModelResource, EnumAttributeService, LocalCacheService);
            $rootScope.dataModelHash = null;
            $rootScope.communicationPlans = {};
            $rootScope.compiledTemplates = {};
            $rootScope.inputTemplatesRegistered = false;

            $rootScope.isDataModelLoaded = false;

        };
        $rootScope.resetDataModel();
        $rootScope.isDataModelLoading = false;
        $rootScope.isUserDataModelLoaded = false;

        $rootScope.reloadDataModel = function() {
            if (!$rootScope.isDataModelLoading) {
                $rootScope.resetDataModel();
                $rootScope.loadDataModel();
                $rootScope.refreshTranslations();
                $rootScope.refreshSupportedLocales();
            }
        };

        function isDataModelComplete(dataModel) {

            // Make sure dataModel contains attributes, categories and layouts
            if (dataModel === null || dataModel.attributes === null || dataModel.categories === null || dataModel.layouts === null) {
                console.log("DataModel is empty ", dataModel);
                return false;
            }

            // Make sure that at least one category is defined
            if ($rootScope.isEmpty(dataModel.categories)) {
                console.log("DataModel does not contain any category");
                return false;
            }

            return true;
        }

        var dataModelLoader = new EtagLoader(DATA_MODEL_TAG_KEY, DATA_MODEL_MAP_KEY);

        $rootScope.loadDataModel = function() {

            if ($rootScope.isDataModelLoading) {
                $log.info("Called while data model is already loading");
            } else if (!$rootScope.isDataModelLoaded) {

                $rootScope.isDataModelLoading = true;
                $rootScope.inputTemplatesRegistered = false;

                dataModelLoader
                    .loadDataByResource(DataModelResource, {},
                                        setDataModel,
                                        restoreDataModel,
                                        errorDataModel)
                    .then(loadUserDataModel)
                    .then(signalDataModelLoaded)
                    .finally(function() {
                        $rootScope.isDataModelLoading = false;
                        InputTemplatesService.loadTemplates();
                    });

            }

        };

        function setDataModel(dataModelHash, dataModelMap, loader) {
            if (isDataModelComplete(dataModelMap)) {
                loader.storeData(dataModelHash, dataModelMap);
                createDataModel(dataModelHash, dataModelMap);
                $log.info("Loaded data model '" + dataModelHash + "':", dataModelMap);
            } else {
                clearDataModel(loader);
                $log.info("Empty or insufficient data model returned:", dataModelMap);
            }
        }

        function restoreDataModel(dataModelHash, dataModelMap, loader) {
            if (isDataModelComplete(dataModelMap)) {
                createDataModel(dataModelHash, dataModelMap);
                $log.info("Restored data model '" + dataModelHash + "' from local storage:", dataModelMap);
            } else {
                clearDataModel(loader);
                $log.info("Empty or insufficient data model returned:", dataModelMap);
            }
        }

        function errorDataModel(errorReason, loader) {
            loader.removeData();
            $rootScope.dataModelHash = null;
            $rootScope.dataModel = {
                errorStatus: errorReason.status,
                errorText: errorReason.data
            };
            $rootScope.communicationPlans = {};
            $rootScope.isDataModelLoading = false;
        }

        function clearDataModel(loader) {
            loader.removeData();
            $rootScope.resetDataModel();
        }

        function createDataModel(dataModelHash, dataModelMap) {

            $rootScope.dataModelHash = dataModelHash;
            $rootScope.dataModel = new DataModel(dataModelMap, $log, $rootScope, $translate, $q, $window, Auth, DataModelResource, EnumAttributeService, LocalCacheService);

            var plans = dataModelMap.communicationPlans || {};
            angular.forEach(plans, function(plan) {
                prepareCommunicationPlan(plan, plan.planCategory, 'planAttributes');
                prepareCommunicationPlan(plan, plan.publicationCategory, 'publicationAttributes');
                prepareCommunicationPlan(plan, plan.unpublicationCategory, 'unpublicationAttributes');
                prepareCommunicationPlan(plan, plan.subscriptionCategory, 'subscriptionAttributes');
            });
            $rootScope.communicationPlans = plans;

        }

        function prepareCommunicationPlan(plan, categoryName, categoryType) {

            if (categoryName) {

                // Load non meta attributes only
                var attributes = $rootScope.dataModel.categoryAttributes(categoryName);
                attributes = attributes.filter(function(attribute) {
                   return !$rootScope.dataModel.isMetaAttribute(attribute);
                });

                // Set renderer and translations for attributes
                $rootScope.prepareAttributes(attributes);

                plan[categoryType] = attributes || [];

            } else {
                plan[categoryType] = [];
            }

        }

        $rootScope.getPreparedCommunicationPlans = function() {
            if (!_.isEmpty($rootScope.communicationPlans)) {
                _.forEach($rootScope.communicationPlans, function(plan) {
                    if (!_.isEmpty(plan.planAttributes)) {
                        $rootScope.prepareAttributes(plan.planAttributes, true);
                    }
                });
            }
            return $rootScope.communicationPlans;
        };

        var userDataModelLoader = new EtagLoader(USER_DATA_MODEL_TAG_KEY, USER_DATA_MODEL_MAP_KEY);

        function loadUserDataModel() {
            return userDataModelLoader
                    .loadDataByResource(UserDataModelResource, {},
                                        setUserDataModel,
                                        restoreUserDataModel,
                                        errorUserDataModel);
        }

        function setUserDataModel(userDataModelHash, userDataModelMap, loader) {

            if (!_.isEmpty(userDataModelMap)) {
                loader.storeData(userDataModelHash, userDataModelMap);
                $rootScope.dataModel.setCategoriesOverview(userDataModelMap.categoriesOverview || {});
                $rootScope.dataModel.setLayoutMapping(userDataModelMap.layoutMapping || {});
                $log.info("Loaded user data model '" + userDataModelHash + "':", userDataModelMap);
            } else {
                clearUserDataModel(loader);
                $log.info("Empty user data model returned");
            }

            $rootScope.isUserDataModelLoaded = true;

        }

        function restoreUserDataModel(userDataModelHash, userDataModelMap, loader) {

            if (!_.isEmpty(userDataModelMap)) {
                $rootScope.dataModel.setCategoriesOverview(userDataModelMap.categoriesOverview || {});
                $rootScope.dataModel.setLayoutMapping(userDataModelMap.layoutMapping || {});
                $log.info("Restored user data model '" + userDataModelHash + "' from local storage:", userDataModelMap);
            } else {
                clearUserDataModel(loader);
                $log.info("Empty user data model returned");
            }

            $rootScope.isUserDataModelLoaded = true;

        }

        function clearUserDataModel(loader) {
            loader.removeData();
            $rootScope.dataModel.setCategoriesOverview({});
            $rootScope.dataModel.setLayoutMapping({});
        }

        function errorUserDataModel(errorReason, loader) {
            clearUserDataModel(loader);
        }

        function signalDataModelLoaded() {
            $rootScope.isDataModelLoaded = true;
            $rootScope.$broadcast('dataModelLoaded');
        }

        $rootScope.$on('userAccountUpdated', function(event, userAccount) {
            $rootScope.dataModel.clearDefaultItems();
        });

        $rootScope.$on('organizationUpdated', function(event) {
            $rootScope.dataModel.clearDefaultItems();
        });

        $rootScope.$on('channelMessageReceived', function(event, eventData) {
            if (hopscotch.getCurrTour()) {
                return;
            }
            if (eventData.event === ChannelService.DATA_MODEL_CHANGED_EVENT) {
                var modal = $modalStack.getTop();

                if (!!modal && modal.value.modalScope.currentLayout == 'edit') {
                    var itemModified = $rootScope.hasItemModified();
                    var message = 'DATAMODEL.UPDATED';
                    if (itemModified) {
                        message = 'DATAMODEL.UPDATED.EDITING.UNSAVED_CHANGES';
                    }

                    var infoDialog = $dialogs.notify('MODAL.CONFIRM_HEADER', message);
                    infoDialog.result.then(function() {
                        window.location.reload();
                    }, function() {
                        window.location.reload();
                    });

                } else if (!!modal || $rootScope.uploaderHasFiles) {
                    $rootScope.shouldReload = true;
                    growl.info('DATAMODEL.UPDATED.EDITING', {
                        ttl: 15000
                    });
                } else {
                    growl.info('DATAMODEL.UPDATED', {
                        ttl: 15000
                    });
                    setTimeout(function() {
                        window.location.reload();
                    }, 1000);
                }
            } else if (eventData.event === ChannelService.EXPORT_SUMMARY_CHANGED_EVENT) {
                if (eventData.data.status === 'FINISHED' && eventData.data.creationUser === $rootScope.user.userId) {
                     var link = "/api/v1/gatherings/" + eventData.data.id + eventData.data.digitalAssetPath + "?download=true";
                     growl.warning('EXPORT.SAVE_SUCCESS_MESSAGE', {
                         variables: {link: link},
                         referenceId: 1,
                         ttl: -1
                     });
                 }
            } else if (eventData.event === ChannelService.FINISHED_VIRUS_SCAN_ON_ASSET) {
                if (eventData.data.virusStatus === 'INFECTED') {
                    var path = eventData.data.filePath;
                    growl.error('VIRUS_SCAN.INFECTED', {
                        variables: {path: path},
                        referenceId: 1,
                        ttl: -1
                    });
                }
            } else if (eventData.event === ChannelService.NEAR_ITEM_LIMIT_EVENT) {
                growl.warning("USAGE_LIMIT.NEAR_ITEM_LIMIT_EVENT", {
                    variables: {
                        usage: eventData.data.usage,
                        limit: eventData.data.limit
                    },
                    ttl: -1
                });
            } else if (eventData.event === ChannelService.PUBLICATION_TASK_CHANGED_EVENT) {
                var publication = eventData.data.publication;
                if (eventData.data.typeOfChange === "updated" && publication.user === $rootScope.user.userId) {
                    // Only notify current user
                    switch (publication.taskStatus) {
                        case 'SUCCESS':
                            growl.info(publication.publicationType === 'ADD' ?
                                    'PUBLICATION_FINISHED.SUCCESS' : 'DEPUBLICATION_FINISHED.SUCCESS', {
                                variables: { count: publication.publishedItemsCount },
                                referenceId: 1,
                                ttl: -1
                            });
                            break;
                        case 'ERROR':
                            growl.error(publication.publicationType === 'ADD' ?
                                'PUBLICATION_FINISHED.ERROR' : 'DEPUBLICATION_FINISHED.ERROR', {
                                variables: { errorMessage: publication.message },
                                ttl: -1
                            });
                            break;
                    }
                }
            } else if (eventData.event === ChannelService.USERGROUPS_CHANGED) {
                UsersGroupService.reloadUsersAndGroups();
            } else if (eventData.event === ChannelService.DATA_MODEL_INVALID_EVENT) {
                if (eventData.data.account.userId === $rootScope.user.userId) {
                    growl.error('DATA_MODEL_ERROR', {
                        variables: {errorMessage: eventData.data.errorMessage},
                        ttl: -1
                    });
                }
            } else if (eventData.event === ChannelService.BROADCAST_MESSAGE) {
                var includeRoles = eventData.data.includeRoles;
                var excludeRoles = eventData.data.excludeRoles;
                var effectiveRoles = $rootScope.user.effectiveRoles;
                if ((eventData.data.includeSelf || (eventData.data.userId !== $rootScope.userId)) &&
                    ((eventData.data.userId === $rootScope.userId) || _.isNil(includeRoles) || _.has(includeRoles, effectiveRoles)) &&
                    ((eventData.data.userId === $rootScope.userId) || _.isNil(excludeRoles) || !_.has(excludeRoles, effectiveRoles))) {
                    var severity = eventData.data.severity || 'success';
                    var params = {};
                    if (!_.isNil(eventData.data.referenceId)) {
                        params.referenceId = eventData.data.referenceId;
                    }
                    if (!_.isNil(eventData.data.variables)) {
                        params.variables = eventData.data.variables;
                    }
                    if (!_.isNil(eventData.data.timeToLive)) {
                        params.ttl = eventData.data.timeToLive;
                    }
                    _.invoke(growl, severity, eventData.data.message, params);
                }
            } else if (eventData.event === ChannelService.BULK_SEARCH_CHANGED_EVENT) {

                // `bulkSearchChanged` event can be received by bulk search and bulk subscriptions, so we
                // need to normalize both event entities as they are a little bit different.
                var bulkSearchOrSubscriptionEntry = eventData.data.bulkSearch || eventData.data.entry;
                if (_.isNil(bulkSearchOrSubscriptionEntry)) {
                    $log.error('Received `bulkSearchChanged` event, but no entry was attached to the event', eventData);
                    return;
                }

                var changedByUserId = bulkSearchOrSubscriptionEntry.user || bulkSearchOrSubscriptionEntry.updatedBy;
                if (changedByUserId === $rootScope.user.userId) {
                    var status = bulkSearchOrSubscriptionEntry.status;
                    if (status === 'RUNNING') {
                        $rootScope.bulkSearchLoading = true;
                        $rootScope.$broadcast('fetchItems');
                    } else if (status === 'READY') {
                        $rootScope.$broadcast('fetchItems');
                        setTimeout(function() {
                            growl.success('BULK_SEARCH.FINISHED');
                        }, 1);
                        $rootScope.bulkSearchStillRunning = false;
                    } else if (status === 'ERROR') {
                        growl.success('BULK_SEARCH.ERROR');
                    }
                    if (bulkSearchOrSubscriptionEntry.finished) {
                        $rootScope.bulkSearchLoading = false;
                    }
                }
            } else if (eventData.event === ChannelService.VALIDATION_TASK_CHANGED_EVENT) {
                var validationTask = eventData.data.validationTask;
                var validationStatus = eventData.data.status;

                if(validationTask.identification === $rootScope.user.userId) {
                    if (validationStatus === 'FINISHED') {
                        growl.success('ITEMS_VALIDATION_FINISHED', {
                            variables: {
                                itemCount: validationTask.validatedItemsCount
                            }
                        });
                    } else if (validationStatus === 'FAILED') {
                        growl.error('ITEMS_VALIDATION_FAILED', {
                            variables: {
                                itemCount: validationTask.validatedItemsCount
                            },
                            ttl: -1
                        });
                    }
                }
            }
        });

        $rootScope.reloadAfterDataModelUpdate = function() {
            if ($rootScope.shouldReload) {
                setTimeout(function() {
                    window.location.reload();
                }, 1000);
            }
        };

        $rootScope.queryCategories = function(query, dataModel, extension, leaf, limit) {
            query = $rootScope.escapeSearchTerm(query);
            var queryParams = {q: query, dataModel: dataModel, extension: extension, leaf: leaf, limit: limit};
            return $http.get(lax_rest_url('datamodel/retrieval/categories'), {params: queryParams}).then(function(res) {
                return res.data;
            });
        };

        $rootScope.cleanupItem = function(item) {
            $rootScope.tmpItem = angular.copy(item);
            // Cleanup values for attributes of type 'MultiReference':
            // Remove all values which are not part of the attribute definition
            var multiRefAttributes = _.filter($rootScope.dataModel.allAttributes(), function(attribute) {
                return attribute.typeName === 'MultiReference';
            });

            var allGroups = _.filter($rootScope.dataModel.allAttributes(), function (attribute) {
                return attribute.typeName === 'Group';
            });

            var groupsWithMultiRefAttribute = _.filter(allGroups, function (attribute) {
                var groupMembers = $rootScope.dataModel.attribute(attribute.params.valueAttribute).members;
                var groupHasMultiRef = false;
                _.forEach(groupMembers, function (attr) {
                    var tmpAttr = $rootScope.dataModel.attribute(attr);
                    if (tmpAttr.typeName === 'MultiReference') {
                        groupHasMultiRef = true;
                    }
                });

                return groupHasMultiRef;
            });

            // No need to do cleanup, when no multi-ref attributes exist
            if (_.isEmpty(multiRefAttributes)) {
                return $rootScope.tmpItem;
            }

            var changedValues = {};
            _.forEach(multiRefAttributes, function(attribute) {

                // No need to filter, if no values for the attribute exist
                var multiRefValues = $rootScope.tmpItem[attribute.name];
                if (_.isEmpty(multiRefValues)) {
                    return;
                }

                var valueAttribute = $rootScope.dataModel.attribute(attribute.params.valueAttribute);
                var newMultiRefValues = [];

                // Create new values collection containing only members of the value attribute
                _.forEach(multiRefValues, function(multiRefValue) {
                    var newMultiRefValue = {};
                    _.forEach(valueAttribute.members, function(member) {
                        if (!_.isEmpty(multiRefValue[member])) {
                            newMultiRefValue[member] = multiRefValue[member];
                        }
                    });
                    newMultiRefValues.push(newMultiRefValue);
                });

                changedValues[attribute.name] = newMultiRefValues;

            });

            var groupModels = Object.keys($rootScope.referencedItems);

            if(!_.isEmpty(groupModels)) {
                _.forEach(groupModels, function(model) {
                    var modelEval = $parse(model.replace("item","tmpItem"));
                    var attributeName = model.split("'").slice(-2).reverse().pop();
                    var attribute = $rootScope.dataModel.attribute(attributeName);

                // No need to filter, if no values for the attribute exist
                var multiRefValues = modelEval($rootScope);

                if (_.isEmpty(multiRefValues) || _.isEmpty(attribute)) {
                    return;
                }

                    var valueAttribute = $rootScope.dataModel.attribute(attribute.params.valueAttribute);
                    var newMultiRefValues = [];

                    // Create new values collection containing only members of the value attribute
                    _.forEach(multiRefValues, function(multiRefValue) {
                        var newMultiRefValue = {};
                        _.forEach(valueAttribute.members, function(member) {
                            if (!_.isEmpty(multiRefValue[member])) {
                                newMultiRefValue[member] = multiRefValue[member];
                            }
                        });
                        newMultiRefValues.push(newMultiRefValue);
                    });

                    modelEval.assign($rootScope, newMultiRefValues);
                });
            }

            if (_.isEmpty(changedValues)) {
                return $rootScope.tmpItem;
            } else {
                var cleanedItem = angular.copy($rootScope.tmpItem);
                angular.extend(cleanedItem, changedValues);
                return cleanedItem;
            }

        };

        // For compatibility with old data models that are not using the group-in-group functionality,
        // we have to make sure that "Object" or "Array" like values of "String" attributes are not stored as such,
        // but rather "stringified" before storing!
        $rootScope.stringifyDeepValues = function(item) {

            var modifiedItem = angular.copy(item);
            var changedValues = _.pickBy(modifiedItem, function(attribute) {
                return (_.isObject(attribute) && !_.isEmpty(attribute));
            });

            angular.forEach(changedValues, function(value, key) {

                if (!angular.isArray(value)) {
                    return;
                }

                var parentAttribute = $rootScope.dataModel.attribute(key);
                if (_.isNil(parentAttribute) || parentAttribute.typeName !== 'Collection') {
                    return;
                }

                angular.forEach(value, function(element) {
                    angular.forEach(element, function(value1, key1) {

                        var attribute = $rootScope.dataModel.attribute(key1);
                        if (_.isNil(attribute) || attribute.baseClass !== 'String') {
                            return;
                        }

                        if (angular.isArray(value1)) {
                            element[key1] = value1.join();
                        } else if (angular.isObject(value1)) {
                            element[key1] = JSON.stringify(value1);
                        }

                    });
                });

            });

            modifiedItem = angular.extend(modifiedItem, changedValues);

            return modifiedItem;
        };

        $rootScope.contactsLoaded = false;

        $rootScope.getContactName = function(organizationId) {
            if ($rootScope.contactsLoaded) {
                var contact = _.find($rootScope.contactsMap, { organizationId: organizationId });
                if (!_.isNil(contact)) {
                    return contact.name;
                }
            }
            return organizationId;
        };

        // This is just a Helper Function that can be used to create the property File-Entries from a Model File
        function logAttributesAndOptions(layoutResponse){
            var layoutstr = "";
            var optionStr = "";

            layoutResponse.forEach(function(layout){
                layout.attributes.forEach(function(attribute){
                    layoutstr += "attribute." + attribute.name + "=" + attribute.label + "\n";
                    if (attribute.typeName == "Enum"){
                        var options = attribute.params.values;
                        options.forEach(function(option){
                            optionStr += "option."+option.key+"="+option.value+"\n";
                        });
                    }
                });

            });
            console.log(layoutstr);
            console.log(optionStr);
        }
        $rootScope.mailTo = function(email){
            $window.location = "mailto:"+email;
        };

        var invitedBuyerTemplates = {
            'tpl/sidebar.tpl.html' : 'tpl/buyer-sidebar.tpl.html',
        };
        var invitedSupplierTemplates = {
            'tpl/sidebar.tpl.html' : 'tpl/supplier-sidebar.tpl.html',
        };

        $rootScope.getRoleSpecificTemplate = function(template) {
            if (hasOrganizationRole("INVITED_BUYER")){
                //when there is a special template for buyers return this, otherwise return the original template
                template = invitedBuyerTemplates[template] || template;
            } else if (hasOrganizationRole("INVITED_SUPPLIER")){
                template = invitedSupplierTemplates[template] || template;
            }
            return template;
        };

        $rootScope.getUserSalutation = function(user) {
            var result = [];
            if (!user || !user.userId) {
                return '';
            }
            _.forEach([user.firstName, user.lastName], function(part) {
                if (!_.isEmpty(part)) {
                    result.push(part);
                }
            });
            if (_.isEmpty(result)) {
                result = user.userId;
            } else {
                result.push("(" + user.userId + ")");
                result = result.join(' ');
            }
            return result;
        };

        $rootScope.getUser = function(userId) {

            var userIdLowerCase = _.toLower(userId);

            if (_.isEmpty(userId) || _.isEmpty($rootScope.users)) {
                return null;
            }

            var user = _.find($rootScope.users, function(user) {
                var normalizedUserId = _.toLower(user.userId);
                return user.id == userId || normalizedUserId == userIdLowerCase;
            });

            return user;
        };

        $rootScope.getUserImageUrl = function(userId) {

            if (_.isEmpty(userId)) {
                return null;
            }

            var imageUrl;
            var user = $rootScope.getUser(userId);
            if (!_.isNil(user) && !_.isEmpty(user.imageUrl)) {
                imageUrl = user.imageUrl;
            }

            return imageUrl;
        };

        $rootScope.userHasImage = function(userId) {
            var user = $rootScope.getUser(userId);
            return !_.isNil(user) && !_.isEmpty(user.imageUrl);
        };

        $rootScope.getUserName = function(userId) {
            if (!userId) {
                return;
            }
            var user = UsersService.getUser(userId);
            return user ? user.displayName : userId;
        };

        $rootScope.getCurrentLayout = function() {
            var layout = $location.url().substring(1, $location.url().length);
            if (layout.indexOf('/') > -1) {
                layout = layout.substring(0, layout.indexOf('/'));
            }
            return layout;
        };

        $rootScope.$on('changePage', function(event,data) {
            $rootScope.$apply(function() {
                $location.path(data.location);
            });
        });

        hopscotch.registerHelper('waitfor', function waitFor(selector, timeout) {
            var currTour = hopscotch.getCurrTour();
            var currStepNum = hopscotch.getCurrStepNum();
            $rootScope.$apply(function() {
                var wait = $interval(function() {
                    if ($(selector).length) {
                        $interval.cancel(wait);
                        hopscotch.startTour(currTour, currStepNum);
                    }
                }, timeout || 500);
            });
            return false;
        });

        hopscotch.registerHelper('navigate', function hopscotchNavigate(location, timeout) {
            var currTour = hopscotch.getCurrTour();
            var currStepNum = hopscotch.getCurrStepNum();
            $rootScope.$apply(function() {
                $location.path(location);
                $timeout(function() {
                    hopscotch.startTour(currTour, currStepNum);
                }, timeout || 500);
            });
            return false;
        });

        hopscotch.registerHelper('click', function(selector, timeout) {
            $timeout(function() {
                $(selector).click();
            }, timeout);
        });

        hopscotch.listen('error', function() {
            var tour = hopscotch.getCurrTour();
            var stepNum = hopscotch.getCurrStepNum();
            var step = tour.steps[stepNum];
            $log.error("Error in step #" + step.id + " of tour '" + tour.id + "'! Probably target does not exist.");
        });

        $rootScope.loadTours = function() {
            TourService.loadTours().then(function(response) {
                $rootScope.tours = response;
                for (var i = 0; i < $rootScope.tours.length; i++) {
                    var tour = $rootScope.tours[i];
                    var tourName = tour.id + (tour.version ? ":" + tour.version : "");
                    var completed = $rootScope.user.completedTours || [];
                    if (tour.startAlways || (tour.startAutomatically === true && !completed.contains(tourName))) {
                        $rootScope.startTour(tour.id);
                        return;
                    }
                }
            });
        };

        function tourClose() {
            var tour = hopscotch.getCurrTour();
            var tourName = tour.id + (tour.version ? ":" + tour.version : "");
            if (tour.closeWithoutConfirm) {
                TourService.setTourCompleted($rootScope.user, tourName, true);
            } else {
                var infoDialog = $dialogs.confirm('TOUR.CLOSED', 'TOUR.START_NEXT_TIME');
                infoDialog.result.then(function() {
                    TourService.setTourCompleted($rootScope.user, tourName, false);
                }, function() {
                    TourService.setTourCompleted($rootScope.user, tourName, true);
                });
            }
        }

        function prepareTour(tour) {
            tour.steps.forEach(function(step) {
                if (step.id) {
                    var translationKey = 'TOUR.' + tour.id + '.' + step.id + '.';
                    step.title = $rootScope.translate(translationKey + 'title', step.title);
                    step.content = $rootScope.translate(translationKey + 'content', step.content);
                }
            });
            if (tour.closeWithoutConfirm) {
                tour.onClose = tourClose;
                tour.onEnd = tourClose;
            } else {
                tour.onClose = tourClose;
            }
            tour.skipIfNoElement = false;
            return tour;
        }

        $rootScope.startTour = function(tourId) {
            if ($rootScope.disableTours) {
                $log.info('App tours have been disabled as per the customUiConfig.');
                return;
            }
            var tour = _.find($rootScope.tours, {id: tourId});
            if (tour) {
                tour = prepareTour(tour);
                $timeout(function() {
                    hopscotch.startTour(tour, 0);
                }, 500);
            }
        };

        var TIME = {
            MILLISECONDS_PER_SECOND: 1000,
            SECONDS_PER_MINUTE: 60,
            SECONDS_PER_HOUR: 3600,
            SECONDS_PER_DAY: 86400,
            SECONDS_PER_WEEK: 604800,
            SECONDS_PER_MONTH: 2419200,
            SECONDS_PER_YEAR: 29030400
        };

        $rootScope.formatDateToSimpleString = function(timestamp) {
            var currentTimestamp = new Date().getTime();
            var diff = (currentTimestamp - timestamp) / TIME.MILLISECONDS_PER_SECOND;
            var diffCount;

            if (diff < TIME.SECONDS_PER_MINUTE) {
                return $translate.instant('ACTIVITY.FEW_SECONDS');
            } else if (diff < TIME.SECONDS_PER_HOUR) {
                diffCount = Math.floor( diff / TIME.SECONDS_PER_MINUTE );
                return (diffCount == 1) ? $translate.instant('ACTIVITY.MINUTE_AGO') : $translate.instant('ACTIVITY.MINUTES_AGO', { count: diffCount });
            } else if (diff < TIME.SECONDS_PER_DAY) {
                diffCount = Math.floor( diff / TIME.SECONDS_PER_HOUR );
                return (diffCount == 1) ? $translate.instant('ACTIVITY.HOUR_AGO') : $translate.instant('ACTIVITY.HOURS_AGO', { count: diffCount });
            } else if (diff < TIME.SECONDS_PER_WEEK) {
                diffCount = Math.floor( diff / TIME.SECONDS_PER_DAY );
                return (diffCount == 1) ? $translate.instant('ACTIVITY.DAY_AGO') : $translate.instant('ACTIVITY.DAYS_AGO', { count: diffCount });
            } else if (diff < TIME.SECONDS_PER_MONTH) {
                diffCount = Math.floor( diff / TIME.SECONDS_PER_WEEK );
                return (diffCount == 1) ? $translate.instant('ACTIVITY.WEEK_AGO') : $translate.instant('ACTIVITY.WEEKS_AGO', { count: diffCount });
            } else if (diff < TIME.SECONDS_PER_YEAR) {
                diffCount = Math.floor( diff / TIME.SECONDS_PER_MONTH );
                return (diffCount == 1) ? $translate.instant('ACTIVITY.MONTH_AGO') : $translate.instant('ACTIVITY.MONTHS_AGO', { count: diffCount });
            } else {
                diffCount = Math.floor( diff / TIME.SECONDS_PER_YEAR );
                return (diffCount == 1) ? $translate.instant('ACTIVITY.YEAR_AGO') : $translate.instant('ACTIVITY.YEARS_AGO', { count: diffCount });
            }
        };

        // Parse a value according to it's type
        $rootScope.parseValue = function(value, type) {
            if (_.isNil(value)) {
                return value;
            }
            if (type === 'date') {
                // Simple ISO date string, without timezone
                return $filter('date')(value, "yyyy-MM-dd");
            } else if (type === 'dateTime' && !_.isDate(value)) {
                return new Date(_.toNumber(value));
            } else {
                return value;
            }
        };

        // State for datepickers
        $rootScope.datepickerOpened = {};

        // Helper to toggle specified datepicker and close all others
        $rootScope.toggleDatepicker = function(evt, name) {

            // Once a datepicker is opened, all others will be closed,
            // because their states are removed and thus become "false".
            var oldState = $rootScope.datepickerOpened[name];
            $rootScope.datepickerOpened = {};
            if (!oldState) {
                $rootScope.datepickerOpened[name] = true;
            }

        };

        $rootScope.isDatepickerOpen = function(name) {
            return $rootScope.datepickerOpened && ($rootScope.datepickerOpened[name] === true);
        };

        // Set renderer and translations for attributes
        $rootScope.prepareAttributes = function(attributes) {
            UrlRetrievalService.clear();
            angular.forEach(attributes, function(attribute) {
                $rootScope.setInputRenderer(attribute);
                $rootScope.prepareAttribute(attribute, true);
            });
        };

        $rootScope.prepareAttribute = function(attribute, forceReloadDynamicEnum) {

            attribute.translatedLabel = attribute.translatedLabel || $rootScope.translateAttribute(attribute);
            attribute.label = attribute.translatedLabel; // FIXME: Ensure that HTML code always uses "translatedLabel" and remove this line!
            attribute.translatedDescription = attribute.translatedDescription || $rootScope.translateAttributeDescription(attribute);
            attribute.description = attribute.translatedDescription; // FIXME: Ensure that HTML code always uses "translatedDescription" and remove this line!

            if (!_.isEmpty(attribute.params)) {
                if (_.isNil(attribute.readonly)) {
                    attribute.readonly = attribute.params.readonly;
                }
                if (attribute.params.dynamicValuesUrl) {
                    if (forceReloadDynamicEnum || _.isEmpty(attribute.params.values)) {
                        UrlRetrievalService.get(attribute.params.dynamicValuesUrl).then(function(data) {
                            attribute.params.values = data;
                            delete attribute.options;
                        });
                    }
                }

                if (EnumAttributeService.isCodelistAttribute(attribute) && attribute.params.codelist) {
                    if (forceReloadDynamicEnum || _.isEmpty(attribute.params.values)) {
                        CodelistRessource.getPairs({name:attribute.params.codelist, limit:0}, function(data) {
                            attribute.params.values = data;
                            delete attribute.options;
                        });
                    }
                } else {
                    EnumAttributeService.getAttributeOptionsAsync(attribute, forceReloadDynamicEnum);
                }
            }

        };

        $rootScope.translateAllOptions = function(attribute, options) {
            var dataModelGatheringKey = $rootScope.organization ? $rootScope.organization.dataModelGatheringKey : null;
            if (!_.isEmpty(dataModelGatheringKey)) {
                _.forEach(options, function(option) {
                    option.translatedOption = $rootScope.translateOption(option, attribute);
                    option.translatedOptionIcon = $rootScope.translateOptionIcon(option, attribute);
                    if (_.isEmpty(option.translatedOptionIconUrl) && !_.isEmpty(option.translatedOptionIcon)) {
                        option.translatedOptionIconUrl = lax_rest_url('gatherings/' + dataModelGatheringKey + '/' + option.translatedOptionIcon);
                    }
                });
            }
        };

        $rootScope.formatAttributeValue = function(object, attribute) {

            if (_.isEmpty(object)) {
                return "";
            }

            var value = object[attribute.name];
            if (_.isEmpty(value)) {
                return "";
            }

            // FIXME: Create generic 'read-only' templates for attributes
            // and add other types (Float, Dimensional, EnumSet, OpenEnumSet etc.)

            var type = attribute.typeName || attribute.baseClass;
            if (type === 'Boolean' || type === 'Enum' || type === 'OpenEnum') {
                value = $filter('formatOptionValue')(value, attribute);
            } else if (type === 'Date') {
                value = $filter('date')(value, $rootScope.getDateFormat(attribute));
            } else if (type === 'Physical') {
                value = $filter('formatPhysicalValue')(value, attribute);
            }

            return value;
        };

        // DEPRECATED: iuParamsFilter should not be used anymore!
        $rootScope.filterAttributeParams = function(attribute, item, user, organization) {

            if (!attribute.params) {
                return;
            }

            var uiParamsFilter = attribute.params.uiParamsFilter;
            if (!uiParamsFilter) {
                return;
            }

            uiParamsFilter = $parse(uiParamsFilter);
            if (!uiParamsFilter) {
                return;
            }

            var context = {
                params: attribute.params,
                item: item,
                user: user,
                organization: organization,
                _: _
            };

            var modifiedParams = uiParamsFilter(context);
            if (!_.isObject(modifiedParams)) {
                $log.error("Returned modified params (", modifiedParams, ") is not an object");
                return;
            }

            _.assign(attribute.params, modifiedParams);

        };

        /**
         * DEPRECATED, in favor of 'EnumAttributeService.getAttributeOptionsAsync()'.
         * Using this method to get dynamically-loaded options has some unexpected behavior due to the nature of the dynamic values.
         */
        $rootScope.getAttributeOptions = function(attribute, forceReloadDynamicEnum) {

            if (_.has(attribute, 'options') && !forceReloadDynamicEnum) {
                return attribute.options;
            } else if (!_.has(attribute, 'params')) {
                return null;
            }

            if (_.has(attribute.params, 'dynamicValuesUrl')) {
                if (forceReloadDynamicEnum || _.isNil(attribute.params.values)) {

                    // FIXME: Due to the usage of asynchronous promise,
                    // this might not work 100% correctly!
                    if (!attribute.loadingDynamicValues) {
                        attribute.loadingDynamicValues = true;
                        $log.debug("Reloading option values for DynamicEnum attribute '%s'", attribute.name);
                        UrlRetrievalService.get(attribute.params.dynamicValuesUrl).then(function(data) {
                            attribute.params.values = data;
                            delete attribute.options;
                            $log.debug("Option values loaded for DynamicEnum attribute '%s'", attribute.name);
                            $rootScope.getAttributeOptions(attribute, false);
                        }).finally(function() {
                            attribute.loadingDynamicValues = false;
                        });
                    }

                }
            }

            var options;
            var optionsParam = $rootScope.dataModel.getAttributeOptionsParam(attribute);
            if (_.isEmpty(optionsParam)) {

                // Get options from referenced attribute, if no options were defined
                var referencedOptionAttribute = $rootScope.dataModel.getReferencedOptionAttribute(attribute);
                if (_.has(referencedOptionAttribute, 'params')) {

                    $rootScope.prepareAttribute(referencedOptionAttribute);
                    options = $rootScope.getAttributeOptions(referencedOptionAttribute, forceReloadDynamicEnum);
                    if (!_.isEmpty(options)) {

                        attribute.options = options;

                        // Set optionsFilter and createNewOption, overriding the corresponding values of the referenced attribute
                        attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter || referencedOptionAttribute.optionsFilter;
                        attribute.createNewOption = attribute.params.skipValuesValidation || referencedOptionAttribute.createNewOption;

                    }

                }

            } else {

                options = _.get(attribute.params, optionsParam);
                if (_.isString(options)) {

                    // Get options from optionList, including filtering by groups
                    attribute.optionList = options;
                    var groupNames = _.get(attribute.params, optionsParam + 'OptionGroups');
                    options = $rootScope.dataModel.optionListOptions(attribute.optionList, groupNames);
                    attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter;

                }

                if (_.isArray(options)) {
                    attribute.options = options;
                    attribute.createNewOption = attribute.params.skipValuesValidation;
                    attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter;
                }

            }

            if (!_.isEmpty(options)) {
                $rootScope.translateAllOptions(attribute, options);
            }

            return options;
        };

        $rootScope.getFilteredDimensions = function(item, attribute, currentValue) {

            // FIXME: 'getAttributeOptions()' has unexpected behavior with dynamically loaded
            // options, use 'EnumAttributeService.getAttributesAsync()' instead.
            var options = $rootScope.getAttributeOptions(attribute);
            var filteredOptions = EnumAttributeService.filterOptions(item, attribute, null, currentValue, options);

            // Add options for all non empty values
            if (_.isObject(currentValue) && !_.isEmpty(currentValue)) {

                var modified = false;
                _.forOwn(currentValue, function(value, key) {

                    // Don't add if value or key are empty
                    if (_.isNil(value) || _.isNil(key)) {
                        return;
                    }

                    // Don't add if key already exists in filtered options
                    if (_.some(filteredOptions, {key: key})) {
                        return;
                    }

                    // Find option in list of all options or create a new entry
                    var option = _.find(options, {key: key});
                    if (!option) {
                        option = createNewOption(key);
                    }

                    if (!modified) {
                        if (filteredOptions === options) {
                            filteredOptions = _.clone(options);
                        }
                        // Add an empty dummy element to identify the additional non empty values
                        if (!_.isEmpty(filteredOptions)) {
                            filteredOptions.push(null);
                        }
                        modified = true;
                    }
                    filteredOptions.push(option);

                });

            }

            return filteredOptions;
        };

        /*
         * Returns the item in the context/layout provided, for example:
         * - 'browse'             -> item is the grid row
         * - 'edit' or 'detail'   -> item is in the scope
         * - otherwise (mass update or custom filter) -> there is no item
         */
        $rootScope.getItemInContext = function(context, attributeScope, gridScope) {
            if (context === 'browse') {
                return gridScope.row.entity;
            } else {
                return attributeScope.item;
            }
        };

        /*
         * Returns the row/entry of the collection where the function is being invoked from.
         */
        $rootScope.getCollectionEntryInContext = function(gridScope) {
            if (!_.isNil(gridScope)) {
                return gridScope.row.entity;
            }

            return null;
        };

        /*
         * Attaches an enum attribute options to the scope and the attribute object itself, based on
         * the context it was called from.
         *
         * @context: layout if available.
         * @attributeScope: the AngularJS scope that the attribute is being rendered in.
         * @gridScope: the angular-ui-grid scope if the attribute is being rendered in a grid row.
         */
        $rootScope.loadContextFilteredOptionsIntoScopeAsync = function(context, attributeScope, gridScope, attribute, currentValue, searchValue, addEmptyOption) {

            var item = $rootScope.getItemInContext(context, attributeScope, gridScope);
            var collectionEntry = $rootScope.getCollectionEntryInContext(gridScope);

            $rootScope.loadFilteredOptionsIntoScopeAsync(attributeScope, item, attribute, collectionEntry, currentValue, searchValue, addEmptyOption);

        };

        /*
         *  DEPRECATED in favor of `loadContextFilteredOptionsIntoScopeAsync()`.
         */
        $rootScope.getContextFilteredOptions = function(context, attributeScope, gridScope, attribute, currentValue, searchValue, addEmptyOption) {
            var item = $rootScope.getItemInContext(context, attributeScope, gridScope);
            return $rootScope.getFilteredOptions(item, attribute, currentValue, searchValue, addEmptyOption);
        };

        /*
         *  DEPRECATED in favor of `loadFilteredOptionsIntoScopeAsync()`.
         *  This cannot be removed, as long as it's used in the data models, to ensure backward compatibility.
         */
        $rootScope.getFilteredOptions = function(item, attribute, currentValue, searchValue, addEmptyOption) {

            // FIXME: 'getAttributeOptions()' has unexpected behavior with dynamically loaded
            // options, use 'EnumAttributeService.getAttributesAsync()' instead.
            var options = $rootScope.getAttributeOptions(attribute, false);
            var filteredOptions = EnumAttributeService.filterOptions(item, attribute, null, currentValue, options);

            // Search via 'propsFilter', which also moves the currentValue option to the beginning, if searchValue is empty
            filteredOptions =
                $filter("propsFilter")(filteredOptions,
                                       { key: searchValue, value: searchValue, translatedOption: searchValue },
                                       currentValue);

            // If first filtered option does not match either searchValue or currentValue,
            // create and add a new option for it at the beginning.
            // Add a new option for searchValue only, if the attribute allows adding new options.
            // Otherwise, use the currentValue, but only if the searchValue is empty and the currentValue is a string
            var matchValue;
            if (attribute.createNewOption && !_.isEmpty(searchValue)) {
                matchValue = searchValue;
            } else if (_.isString(currentValue) && _.isEmpty(searchValue)) {
                matchValue = currentValue;
            }
            if (!_.isEmpty(matchValue)) {

                var firstOption = filteredOptions[0];
                if (!matchesOption(firstOption, matchValue)) {
                    if (filteredOptions === options) {
                        filteredOptions = _.clone(options);
                    }
                    var newOption = createNewOption(matchValue);
                    filteredOptions.unshift(newOption);
                }
            }

            // If currentValue is Array and filtered options does not match any currentValue
            // Create and add a new option at the begining of filtered options for unmatched values
            if (_.isArray(currentValue)) {
                _.forEach(currentValue,function(val) {
                    if (!_.isNil(val)) {
                        var optionNotFound = _.every(options,function(opt) {
                            return !matchesOption(opt, val);
                        });
                        if (optionNotFound) {
                            var newOption = createNewOption(val);
                            filteredOptions.unshift(newOption);
                        }
                    }
                });
            }

            if (addEmptyOption) {
                if (filteredOptions === options) {
                    filteredOptions = _.clone(options);
                }
                var pos = _.isEmpty(filteredOptions) ? 0 : 1;
                filteredOptions.splice(pos, 0, {'key': null, 'translatedOption': $rootScope.translate("ATTRIBUTE.EMPTY", "empty")});
            }

            return filteredOptions;
        };

        /*
         * Attaches an enum attribute options to the scope and the attribute object itself.
         */
        $rootScope.loadFilteredOptionsIntoScopeAsync = function(scope, item, attribute, collectionEntry, currentValue, searchValue, addEmptyOption) {

            EnumAttributeService.getAttributeOptionsAsync(attribute, false)
                .then(function(options) {

                    // Cache the options in the attribute object for the current session
                    if (_.isEmpty(attribute.options)) {
                        attribute.options = angular.copy(options);
                    }

                    var filteredOptions = EnumAttributeService.filterOptions(item, attribute, collectionEntry, currentValue, options);

                    // Search via 'propsFilter', which also moves the currentValue option to the beginning, if searchValue is empty
                    filteredOptions =
                        $filter("propsFilter")(filteredOptions,
                                               { key: searchValue, value: searchValue, translatedOption: searchValue },
                                               currentValue);

                    // If first filtered option does not match either searchValue or currentValue,
                    // create and add a new option for it at the beginning.
                    // Add a new option for searchValue only, if the attribute allows adding new options.
                    // Otherwise, use the currentValue, but only if the searchValue is empty and the currentValue is a string
                    var matchValue;
                    if (attribute.createNewOption && !_.isEmpty(searchValue)) {
                        matchValue = searchValue;
                    } else if (_.isString(currentValue) && _.isEmpty(searchValue)) {
                        matchValue = currentValue;
                    }
                    if (!_.isEmpty(matchValue)) {

                        var firstOption = filteredOptions[0];
                        if (!matchesOption(firstOption, matchValue)) {
                            if (filteredOptions === options) {
                                filteredOptions = _.clone(options);
                            }
                            var newOption = createNewOption(matchValue);
                            filteredOptions.unshift(newOption);
                        }
                    }

                    // If currentValue is Array and filtered options does not match any currentValue
                    // Create and add a new option at the begining of filtered options for unmatched values
                    if (_.isArray(currentValue)) {
                        _.forEach(currentValue,function(val) {
                            if (!_.isNil(val)) {
                                var optionNotFound = _.every(options,function(opt) {
                                    return !matchesOption(opt, val);
                                });
                                if (optionNotFound) {
                                    var newOption = createNewOption(val);
                                    filteredOptions.unshift(newOption);
                                }
                            }
                        });
                    }

                    if (addEmptyOption) {
                        if (filteredOptions === options) {
                            filteredOptions = _.clone(options);
                        }
                        var pos = _.isEmpty(filteredOptions) ? 0 : 1;
                        filteredOptions.splice(pos, 0, {'key': null, 'translatedOption': $rootScope.translate("ATTRIBUTE.EMPTY", "empty")});
                    }

                    // In Set attributes, we want to keep any pre-filled values (for example via an import) which
                    // are not part of the valid options of the attribute, and rely on the validations
                    // to report using an invalid option.
                    // _.isNil(searchValue) indicates that we are not in a search state!
                    if (_.isArray(currentValue) && _.isNil(searchValue)) {
                        _.forEach(currentValue, function(value) {
                            var foundOption = _.find(filteredOptions, { key: value });
                            if (_.isNil(foundOption)) {
                                // One of the current values is not part of the attribute options, so we add it in order to keep it available for
                                // the ui-select.
                                filteredOptions.unshift({ key: value, value: value, translatedOption: value });
                            }
                        });
                    }
                    // translate the options
                    $rootScope.translateAllOptions(attribute, filteredOptions);

                    $timeout(function() {
                        scope.options = filteredOptions;
                    }, 0);
                });
        };

        function matchesOption(option, search) {
            if (!_.isObject(option) || _.isEmpty(option) || _.isEmpty(search)) {
                return false;
            }
            search = _.toLower(search);
            return _.toLower(option.key) === search ||
                    _.toLower(option.value) === search ||
                    _.toLower(option.translatedOption) === search;
        }

        $rootScope.isNewOption = function(option) {
            return !_.isObject(option) || _.isEmpty(option) || option.isNew;
        };

        function createNewOption(key) {
            if (!_.isEmpty(key)) {
                return {
                    key: key,
                    value: key,
                    translatedOption: key,
                    isNew: true
                };
            }
        }

        $rootScope.setGridAdditionalCategory = function(scope, keyVariable, valueVariable, attribute) {

            var additionalModule = _.get(attribute, 'params.additionalModule');
            scope[valueVariable] = {
                code: null,
                name: null,
                translatedLabel: null,
                formattedLabel: null
            };

            scope.$watch(keyVariable, function(key) {
                scope[valueVariable].formattedLabel = key;
                $rootScope.loadAdditionalCategory(key, additionalModule).then(function(value) {
                    scope[valueVariable].code = value.code;
                    scope[valueVariable].name = value.id;
                    scope[valueVariable].translatedLabel = value.title;
                    scope[valueVariable].formattedLabel = $filter('additionalCategoryFormatter')(value);
                });
            });

        };

        $rootScope.loadAdditionalCategory = function(additionalCategoryNameOrCode,
                                                     additionalModule) {
            return AdditionalCategoryService.loadAdditionalCategory(additionalCategoryNameOrCode, additionalModule);
        };

        $rootScope.loadOptionsIntoScope = function(scope, getOptionsPromise, keyVariable) {

            getOptionsPromise.then(function(result) {
                scope.options = result;

                scope.$watch(keyVariable, function(key) {
                    var value = findKeyValueObject(scope.options, key);
                    value = value || createNewOption(key);
                    scope.option = value;
                });
            });

        };

        function findKeyValueObject(keyValueObjects, key) {
            if (_.isEmpty(key)) {
                return null;
            } else {
                return _.find(keyValueObjects, { key: key });
            }
        }

        $rootScope.getValuesFormat = function(attribute, defaultFormat) {
            defaultFormat = defaultFormat || ['label'];
            var valuesFormat = attribute.valuesFormat || defaultFormat;
            _.forEach(valuesFormat, function(format, index) {
                // Replace each space with non-breakable space, for otherwise HTML would not render it
                valuesFormat[index] = _.replace(format, / /g, '\xa0');
            });
            return valuesFormat;
        };

        $rootScope.getFilter = function(filterName) {
            if (Auth.isLoggedIn && !$rootScope.loggingOut && $injector.has(filterName + 'Filter')) {
                return $filter(filterName);
            } else {
                return null;
            }
        };

        $rootScope.getService = function(serviceName) {
            if (Auth.isLoggedIn && !$rootScope.loggingOut && $injector.has(serviceName)) {
                return $injector.get(serviceName);
            } else {
                return null;
            }
        };

        $rootScope.filterCollection = function(collection, item, attribute) {

            // Ensure collection is not null or undefined
            collection = collection || [];

            var filterName = attribute.params.uiCollectionFilter;
            if (_.isNil(filterName)) {
                return collection;
            }

            var filter = $rootScope.getFilter(filterName);
            if (_.isNil(filter)) {
                return collection;
            }

            var user = $rootScope.user;
            var organization = OrganizationService.getOrganizationSnapshot();

            var filteredCollection = filter(collection, item, attribute, user, organization);
            if (!_.isNil(filteredCollection)) {
                collection = filteredCollection;
            }

            return collection;
        };

        $rootScope.callout = function(target, message, config) {
            var defaultConfig = {
                id: 'callout-' + target,
                target: '#' + target,
                placement: 'top',
                content: message
            };
            calloutLogic(config, defaultConfig);
        };

        $rootScope.calloutWithId = function(target, id, message, config) {
            var defaultConfig = {
                id: 'callout-' + id,
                target: target,
                placement: 'top',
                content: message
            };
            calloutLogic(config, defaultConfig);
        };

        function calloutLogic(config, defaultConfig) {
            config = config ? angular.extend(defaultConfig, config) : defaultConfig;
            var calloutMgr = hopscotch.getCalloutManager();
            calloutMgr.removeAllCallouts();
            calloutMgr.createCallout(config);
        }

        $rootScope.removeAllCallouts = function() {
            hopscotch.getCalloutManager().removeAllCallouts();
        };

        var statusLabels = {
            ACCEPTED: 'label-success',
            BLOCKED: 'label-info',
            DEFERRED: 'label-default',
            DELIVERED: 'label-primary',
            ENDED: 'label-success',
            ERROR: 'label-danger',
            ESTABLISHED: 'label-success',
            EVALUATING: 'label-warning',
            EXCEPTION: 'label-warning',
            EXPORTING: 'label-info',
            FAILED: 'label-error',
            FATAL: 'label-danger',
            FINISHED: 'label-success',
            IGNORED: 'label-default',
            IMPORTED: 'label-success',
            IMPORTING: 'label-info',
            INACTIVE: 'label-default',
            INVITED: 'label-info',
            OPEN: 'label-warning',
            PENDING: 'label-info',
            PENDING_PROCESSING: 'label-default',
            PROCESSED: 'label-success',
            PROCESSING: 'label-info',
            RECEIVED: 'label-primary',
            REJECTED: 'label-warning',
            REVIEWED: 'label-danger',
            RUNNING: 'label-info',
            SENDING: 'label-info',
            STARTED: 'label-info',
            SUCCESS: 'label-success',
            UNEXPECTED_DELAY: 'label-warning',
            WARNING: 'label-warning'
        };

        $rootScope.getStatusLabel = function(status) {
            var label = statusLabels[status] || 'label-default';
            return label;
        };

        $rootScope.hasWatchlistItems = function() {
            return WatchlistService.hasWatchlistItems();
        };

        $rootScope.getWatchlistItemCount = function() {
            return WatchlistService.getWatchlistItemCount();
        };

        $rootScope.setSectionAttributeParam = function(layoutName, sectionName, attribute, paramName, defaultValue, item) {
            var paramValue;
            if (attribute.additionalParams) {
                paramValue = attribute.additionalParams[paramName];
            } else {
                paramValue =
                    $rootScope.dataModel.sectionAttributeParam(layoutName,
                                                               item,
                                                               sectionName,
                                                               attribute.name,
                                                               paramName);
            }
            if (paramValue === undefined || paramValue === null) {
                paramValue = defaultValue;
            }
            attribute[paramName] = paramValue;
        };

        $rootScope.getItemTitle = function(layoutName, item) {
            var layout = $rootScope.dataModel.layout(layoutName);
            var filterName = layout.layoutOptions ? layout.layoutOptions.itemTitleFilter : null;
            if (_.isNil(filterName)) {
                return item.primaryKey__;
            }

            var filter = $rootScope.getFilter(filterName);
            if (_.isNil(filter)) {
                return item.primaryKey__;
            }
            return filter(item, $rootScope.user, $rootScope.organization);
        };

        $rootScope.getStringLengthMessage = function(current, expected) {
            var result = "";
            var maxValue = 0;

            if (!current) {
                current = 0;
            }

            if (_.isArray(expected)) {
                if (expected.length === 1) {
                    maxValue = expected[0];
                }
            } else if (expected) {
                maxValue = expected;
            }
            var diff = maxValue - current;
            if (maxValue == 0) {
                result = current;
            } else {
                result = $rootScope.translate('STRING.LENGTH.MAX', "", {
                    maxValue: maxValue,
                    current: current
                });
            }

            return result;
        };

        $rootScope.isDefaultValueForAttribute = function(attributeName, newValue, oldValue) {
            var attribute = $rootScope.dataModel.attribute(attributeName);
            var isDefault = false;
            if (attribute && attribute.typeName == "Dimensional") {
                // Checking given value is default value for 'Dimensional' attributes
                var removedEmptyKeysObject = _.omitBy(newValue, function(e) {
                    return _.isEmpty(e);
                });
                isDefault = (_.isEmpty(oldValue) && _.isEmpty(removedEmptyKeysObject)) || $rootScope.isEqual(oldValue, removedEmptyKeysObject);
            }
            return isDefault;
        };

        $rootScope.splitStringByOuterParentheses = function(str) {
            str = _.trim(str) || '';
            // splitting the query string by AND|OR if it exists outside of parentheses
            var strArr = _(str).split(/(AND|OR)(?![^(]*\))/).without("").value();
            strArr = _.map(strArr, function(s) {
                s = _.trim(s);
                // removing outer parentheses if exists
                if (s[0] ==  '(' && s[s.length-1]==')') {
                    s = s.substring(1, s.length-1);
                }
                return s;
            });
            return _.isEmpty(strArr) ? [str] : strArr;
        };

        $rootScope.tagTransform = function (newTag) {
            var item = {
                key: newTag,
                value: newTag
            };
            return item;
        };

        // Initialize the usage of a user preferences stored grid state:
        // Set the scope and gridApi as well as the preferenceKey to store the state,
        // optionally set a 'gridStateKey' if the preference value is an Object.
        // 'gridStateKey' and 'defaultColumDefs' can either be actual values or functions to return the value.
        // Initialization will attach callbacks to column position, size, visibility and sort changes
        // and add a menu entry to reset the grid state to the specified default column defs
        $rootScope.initGridState = function(scope, gridApi, preferenceKey, gridStateKey, defaultColumnDefs) {

            if (_.isNil(scope) || _.isNil(gridApi) || _.isNil(preferenceKey)) {
                return;
            }

            // Attache callbacks to various grid changes, if available
            if (gridApi.colMovable) {
                gridApi.colMovable.on.columnPositionChanged(scope, function() {
                    $rootScope.updateGridState(scope, gridApi, preferenceKey, gridStateKey);
                });
            }
            if (gridApi.colResizable) {
                gridApi.colResizable.on.columnSizeChanged(scope, function() {
                    $rootScope.updateGridState(scope, gridApi, preferenceKey, gridStateKey);
                });
            }
            gridApi.core.on.columnVisibilityChanged(scope, function() {
                $rootScope.updateGridState(scope, gridApi, preferenceKey, gridStateKey);
            });
            gridApi.core.on.sortChanged(scope, function() {
                $rootScope.updateGridState(scope, gridApi, preferenceKey, gridStateKey);
            });
            if (_.isObject(scope.enableWrapping) && _.isUndefined(scope.$$listeners[preferenceKey])) {
                scope.$on(preferenceKey, function() {
                    $rootScope.updateGridState(scope, gridApi, preferenceKey, gridStateKey);
                });
            }

            // Add 'reset' menu item
            $timeout(function() {
                gridApi.core.addToGridMenu(gridApi.grid, [{
                    title: $translate.instant('RESET_GRID_COLUMNS'),
                    action: function () {
                        $rootScope.resetGridState(scope, gridApi, preferenceKey, gridStateKey, defaultColumnDefs);
                    },
                    order: 210
                }]);
            });

            // Restore state, if available
            $rootScope.restoreGridState(scope, gridApi, preferenceKey, gridStateKey);

        };

        // Reset the specified gridState:
        // Remove the local storage state and reset to the default column defs
        $rootScope.resetGridState = function(scope, gridApi, preferenceKey, gridStateKey, defaultColumnDefs) {

            if (_.isNil(scope) || _.isNil(gridApi) || _.isNil(preferenceKey)) {
                return;
            }

            if (_.isFunction(gridStateKey)) {
                gridStateKey = gridStateKey();
            }

            // Depending on gridStateKey, either remove the complete value or only the associated value
            var localGridStates = angular.fromJson(localStorage.getItem(preferenceKey));
            if (!_.isEmpty(localGridStates)) {
                if (_.isNil(gridStateKey)) {
                    localGridStates = null;
                } else {
                    delete localGridStates[gridStateKey];
                }
            }

            // Update local storage and user preferences accordingly
            if (_.isEmpty(localGridStates)) {
                localStorage.removeItem(preferenceKey);
                UserPreferencesResource.delete({ key: preferenceKey });
            } else {
                var gridStateJson = angular.toJson(localGridStates);
                localStorage.setItem(preferenceKey, gridStateJson);
                UserPreferencesResource.update({ key: preferenceKey }, gridStateJson);
            }

            // Restore state from default column defs
            var defaultGridState = toGridState(defaultColumnDefs);

            if (_.isObject(scope.enableWrapping) && _.isBoolean(scope.enableWrapping[preferenceKey])) {
                scope.enableWrapping[preferenceKey] = false;
            }

            restoreGridState(scope, gridApi, defaultGridState);

        };

        // Restore the specified gridState from the local storage
        // or reset it to the default column defs, if specified
        $rootScope.restoreGridState = function(scope, gridApi, preferenceKey, gridStateKey, defaultColumnDefs) {

            if (_.isNil(scope) || _.isNil(gridApi) || _.isNil(preferenceKey)) {
                return;
            }

            if (_.isFunction(gridStateKey)) {
                gridStateKey = gridStateKey();
            }

            var localGridStates = angular.fromJson(localStorage.getItem(preferenceKey)) || {};
            var localGridState = _.isNil(gridStateKey) ? localGridStates : localGridStates[gridStateKey];

            // Restore state from local storage or default column defs, if specified
            if (_.isEmpty(localGridState) && !_.isEmpty(defaultColumnDefs)) {
                localGridState = toGridState(defaultColumnDefs);
            }

            if (!_.isEmpty(localGridState) && _.isBoolean(localGridState.enableWrapping)) {
                scope.enableWrapping[preferenceKey] = localGridState.enableWrapping;
            }
            restoreGridState(scope, gridApi, localGridState);

        };

        // Update the specified gridState:
        // Compare the local storage state and the current grids state,
        // only update if they differ
        $rootScope.updateGridState = function(scope, gridApi, preferenceKey, gridStateKey) {

            if (_.isNil(scope) || _.isNil(gridApi) || _.isNil(preferenceKey)) {
                return;
            }

            // Don't update during a restore
            if ($rootScope.isGridStateRestoring) {
                return;
            }

            // Get local storage state and current grid state to compare
            var localGridStates = angular.fromJson(localStorage.getItem(preferenceKey)) || {};
            var currentGridState = gridApi.saveState.save();
            delete currentGridState.selection;

            //Check for coloumnWrapping
            if (_.isObject(scope.enableWrapping)) {
                currentGridState.enableWrapping = scope.enableWrapping[preferenceKey];
            }

            if (_.isFunction(gridStateKey)) {
                gridStateKey = gridStateKey();
            }

            // Check if current state has actually changed
            var localGridState = _.isNil(gridStateKey) ? localGridStates : localGridStates[gridStateKey];
            if (!_.isEqual(localGridState, currentGridState)) {

                if (_.isNil(gridStateKey)) {
                    localGridStates = currentGridState;
                } else {
                    localGridStates[gridStateKey] = currentGridState;
                }

                // Updatre in local storage and in user preferences
                var gridStateJson = angular.toJson(localGridStates);
                localStorage.setItem(preferenceKey, gridStateJson);
                UserPreferencesResource.update({ key: preferenceKey }, gridStateJson);

            }

        };

        // Restore the specified grid state
        function restoreGridState(scope, gridApi, defaultGridState) {

            if (!_.isEmpty(defaultGridState)) {

                $rootScope.isGridStateRestoring = true;

                $timeout(function() {

                    // Reset the 'orderCache' because otherwise resetting the original positions does not work
                    gridApi.grid.moveColumns = { orderCache : [] };

                    gridApi.saveState.restore(scope, defaultGridState);
                    $rootScope.isGridStateRestoring = false;

                });

            }

        }

        // Create a grid state for the specified column defs
        function toGridState(columnDefs) {

            if (_.isFunction(columnDefs)) {
                columnDefs = columnDefs();
            }

            // 'Convert' column defs to 'column' states
            var columns = [];
            _.forEach(columnDefs, function(columnDef) {
                columns.push({
                    filters: [{}],
                    name: columnDef.name,
                    pinned: columnDef.pinnedRight ? "right" : columnDef.pinnedLeft ? "left": "",
                    sort: {},
                    visible: columnDef.defaultVisible === false ? false : true,
                    width: columnDef.width
                });
            });

            // Grid state will only contain column states
            var gridState = {
                columns: columns
            };

            return gridState;
        }

        $rootScope.getMaxGrowlMessages = function (dataReference) {
            var maxMessages = 5;
            if (dataReference == 0) {
                //notifications-top
                maxMessages = Math.floor(($window.innerHeight / 62) / 3);
            } else if (dataReference == 1) {
                //notifications-bottom
                maxMessages = Math.floor(($window.innerHeight / 52) / 3);
            }

            return maxMessages;
        };

        function migrateLocalStorage() {
            var LOCAL_STORAGE_VERSION = 2;
            var VERSION_KEY = 'localStorageVersion';
            var localStorage = window.localStorage;

            try {
                var version = localStorage.getItem('localStorageVersion');

                if (_.isNil(version) || version != LOCAL_STORAGE_VERSION) {
                    var keys = Object.keys(localStorage);

                    // We need to clean up any leftovers of option lists
                    _.forEach(keys, function(key) {
                        if (_.includes(key, 'optionList')|| _.includes(key, 'translations')) {
                            localStorage.removeItem(key);
                        }
                    });

                    localStorage.setItem(VERSION_KEY, LOCAL_STORAGE_VERSION);
                }
            } catch (exception) {
                $log.error('Error while migrating local storage', exception);
            }
        }

        /**
         * Creates a base64 out of the JSON combination of passed objects. Can be used to create
         * unique identifiers to be used as trackers in an angular-ng-repeat.
         */
        $rootScope.getCombinedObjectsBase64 = function() {
            // Using the `arguments` instead of `...` operand because of ES5!
            var objects = Array.prototype.slice.call(arguments);

            var combinedString = _.map(objects, function(object) {
                return JSON.stringify(object);
            }).join(',');

            var base64 = btoa(encodeURIComponent(combinedString));
            return base64;
        };

        $rootScope.blockDuringDataModelDeployment = function() {
            // We only block the UI if there is no previous data model deployed.
            if ($rootScope.organization.isDataModelDeploying &&
                _.isNil($rootScope.organization.dataModelHash)) {
                return true;
            } else {
                return false;
            }

        };

        // FIXME: Use the libary instead (https://github.com/miguelmota/is-valid-hostname)
        $rootScope.isValidHostname = function(value) {
            if (typeof value !== 'string') return false;
            var validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
            if (!validHostnameChars.test(value)) {
                return false;
            }
            if (value.endsWith('.')) {
                value = value.slice(0, value.length - 1);
            }
            if (value.length > 253) {
                return false;
            }
            var labels = value.split('.');
            var isValid = labels.every(function (label) {
                var validLabelChars = /^([a-zA-Z0-9-]+)$/g;
                var validLabel = validLabelChars.test(label) && label.length < 64 && !label.startsWith('-') && !label.endsWith('-');
                return validLabel;
            });
            return isValid;
        };

        $rootScope.localCacheReady = function() {
            return LocalCacheService.isInitialized();
        };

        /**
         * Loads the freshworks support widget, documentation of the widget can be found here:
         * https://developers.freshdesk.com/widget-api
         */
        $rootScope.loadSupportWidget = function() {

            var configs = _.defaults($rootScope.supportWidget, {
                // Default BYRD widget configs:
                widgetId: 103000007836,
                // In the Freshworks account, we can setup all the languages' labels, and also flag the
                // primary language. The language will be set depending on the browser's locale, if it wasn't found, it will
                // fallback to the primary one. This approach is better than loading every individual language file and providing them
                // to the widget.
                // Also, we can override any label per the following:
                setLabels: {
                    "de": {
                        "banner": "BYRD Support",
                        "contact_form": {
                            "title": "Ihre Anfrage",
                            "email": "E-Mail",
                            "submit": "Senden",
                            "confirmation": "Vielen Dank für Ihre Anfrage"
                        }
                    },
                    "en": {
                        "banner": "BYRD Support",
                        "contact_form": {
                            "title": "Your Inquiry",
                            "email": "E-Mail",
                            "submit": "Send",
                            "confirmation": "Thank you for your Inquiry"
                        }
                    }
                }
            });

            // We use server property ALLOW_SUPPORT_WIDGET to check if widget should be disabled or enabled
            if (!$rootScope.systemSettings.ALLOW_SUPPORT_WIDGET && !configs.enabled) {
                return;
            }

            // 'fwSettings' on the window object are needed for the freshworks widget
            // to function properly
            window.fwSettings = {
                widget_id: configs.widgetId
            };

            if (!_.isFunction(window.FreshworksWidget)) {
                var n = function(){
                    n.q.push(arguments);
                };
                n.q = [];
                window.FreshworksWidget = n;
            }

            requirejs([
                "https://euc-widget.freshworks.com/widgets/" + configs.widgetId + ".js"
            ], function() {
                FreshworksWidget("setLabels", configs.setLabels);

                if (configs.prefillTicketForm) {
                    FreshworksWidget('prefill', 'ticketForm', prefillTicketForm);
                }
            });
        };

        $rootScope.$on('userLoggedIn', function() {
            $rootScope.loadSupportWidget();
        });

        $rootScope.showServiceApps = function() {
           return Auth.hasPermission(Auth.OBJECT_TYPE_UI, 'view.catalog') ||
               Auth.hasPermission(Auth.OBJECT_TYPE_UI, 'view.shoppingcart');
        };
    }
);
})();