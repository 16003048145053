angular.module('llax')
    .controller('PublicationStatusController',
        function(CommunicationChannelService, $scope, ItemResource) {
            function loadPublicationDestinations() {
                return CommunicationChannelService.loadPublicationDestinations().then(function(result) {
                    $scope.communicationChannels = result.communicationChannels;
                    $scope.organizations = result.organizations;
                });
            }

            $scope.loadPublications = function(primaryKey) {
                if (_.isNil($scope.itemPublications)) {
                    ItemResource.getPublications({primaryKey: primaryKey}, function(publications) {
                        $scope.itemPublications = _.filter(publications, { publishedStatus : "PUBLISHED" });
                        loadPublicationDestinations().then(function(data) {
                            $scope.isLoading = false;
                            _.forEach($scope.itemPublications, function(publication) {
                                publication.organization = _.find($scope.organizations, { organizationId : publication.destinationId });
                                // Check the destinationType and/or the subDestination (which only exists for communication channel publications!)
                                if (publication.destinationType == 'IN_PLATFORM' ||
                                (_.isNil(publication.destinationType) && _.isNil(publication.subDestination))) {
                                    // load organization for publication.destinationId
                                    publication.organization = _.find($scope.organizations, { organizationId : publication.destinationId });
                                } else if (publication.destinationType == 'COMMUNICATION_CHANNEL' ||
                                (_.isNil(publication.destinationType) && !_.isNil(publication.subDestination))) {
                                    // load communicationChannel for publication.destinationId
                                    publication.communicationChannel = _.find($scope.communicationChannels, function(ch) {
                                        return (!publication.subDestination || (publication.subDestination == ch.subDestinationKey)) &&  ch.id == publication.destinationId;
                                    });
                                }

                                // If still nothing is set, first try to load organization and then communication channel
                                if (_.isNil(publication.organzation) && _.isNil(publication.communicationChannel)) {
                                    // load organization for publication.destinationId,
                                    // if no organization found, load communicationChannel for publication.destinationId
                                    publication.organization = _.find($scope.organizations, { organizationId : publication.destinationId });
                                    if (_.isNil(publication.organization)) {
                                        publication.communicationChannel = _.find($scope.communicationChannels, function(ch) {
                                            return (!publication.subDestination || (publication.subDestination == ch.subDestinationKey)) &&  ch.id == publication.destinationId;
                                        });
                                    }
                                }
                            });
                            $scope.itemPublicationsByOrg = _.filter($scope.itemPublications, function(p) {
                                return !!p.organization;
                            });
                            $scope.itemPublicationsByChannel = _.filter($scope.itemPublications, function(p) {
                                return !!p.communicationChannel;
                            });
                        });
                    });
                }
            };

            $scope.showPublicationStatus = function() {
                $scope.loadPublications($scope.item.primaryKey__);
            };

        });