angular.module('llax.filters', [])
.filter('formatOptionValue', function($rootScope) {
    return function(value, attribute) {
        if (_.isEmpty(value) || !_.isObject(attribute)) {
            return value;
        }
        var op;
        // FIXME: Instead of "translating" the option every time, keep the translated options in the attribute
        angular.forEach(attribute.options, function(option) {
            if (option.key === value) {
                op = option;
                return;
            }
        });

        var result = $rootScope.translateOption(op || value, attribute);

        // FIXME: Depending on 'attribute.valuesFormat', also show key
        return result;
    };
})
.filter('filterPhysicalValues', function(PhysicalAttributeService) {
    return function(units, value) {

        var result = [];

        var split = PhysicalAttributeService.splitValue(value);
        if (split.inputValue === "") {
            return result;
        }

        sortedUnits = [];
        tempSortedUnits = [];

        _.forEach(units, function(unit) {
            if (unit.value == split.unitValue || unit.key == split.unitValue) {
                sortedUnits.push(unit);
            }
        });

        _.forEach(units, function(unit) {
            if (_.startsWith(_.toLower(unit.value), _.toLower(split.unitValue)) === true) {
                sortedUnits.push(unit);
            } else {
                tempSortedUnits.push(unit);
            }
        });

        tempSortedUnits = _.sortBy(tempSortedUnits, function(unit) {
            return unit.value;
        });
        _.forEach(tempSortedUnits, function(unit) {
            sortedUnits.push(unit);
        });

        sortedUnits = _.uniqBy(sortedUnits, 'key');
        for (var i = 0; i < sortedUnits.length; i++) {
            var unit = sortedUnits[i];
            if (split.unitValue === "" || angular.lowercase(unit.value).indexOf(angular.lowercase(split.unitValue)) > -1) {
                result.push({
                    key: "" + split.inputValue.trim() + " " + unit.key,
                    value: "" + split.inputValue.trim() + " " + unit.value
                });
            }
        }

        return result;
    };
})
.filter('formatPhysicalValue', function($rootScope, PhysicalAttributeService) {
    return function(value, attribute) {
        if (_.isEmpty(value) || !_.isObject(attribute)) {
            return value;
        }
        var units = $rootScope.getTranslatedOptions(attribute);
        var result = PhysicalAttributeService.formatValue(value, units);
        return result;
    };
})
.filter('formatFloatValue', function($locale, OrganizationService) {
    return function(value) {

        if (_.isNil(value)) {
            return;
        }

        var result = _.toString(value);

        if ($locale.NUMBER_FORMATS.DECIMAL_SEP !== '.' &&
                OrganizationService.getOrganizationSnapshot().localeSpecificNumberFormat) {
            result = _.replace(result, '.', $locale.NUMBER_FORMATS.DECIMAL_SEP);
        }

        return result;
    };
})
.filter('objectToArray', function() {
    return function(input) {
        var out = [];
        for (var i in input) {
            out.push(input[i]);
        }
        return out;
    };
})
.filter('range', function() {
  return function(input, min, max, padded) {
    var val;
    min = parseInt(min);
    max = parseInt(max);
    for (var i=min; i<=max; i++) {
      val = (padded && i < 10) ? '0' + i : i;
      input.push(val);
    }
    return input;
  };
})
.filter('regex', function() {
  return function(input, field, regex) {
      var patt = new RegExp(regex);
      var out = [];
      for (var i = 0; i < input.length; i++){
          if(patt.test(input[i][field]))
              out.push(input[i]);
      }
    return out;
  };
})
// FIXME: It's "filename"
.filter('fileName', function($filter) {
  return function(url) {
    if (_.isEmpty(url)) {
        return null;
    }
    var fileName = decodeURI(url.substring(url.lastIndexOf('/') + 1));
    // return full URL when no direct file is provided
    if (_.isEmpty(fileName) || $filter('isFileType')(url, 'website')) {
      fileName = url;
    }
    return fileName;
  };
})
// FIXME: There already is a directive "filetype"! We have to merge this with the 'fileType' and 'isFileType' filters!
.filter('fileType', function($filter) {
  return function(url) {
    if (_.isEmpty(url)) {
      return "";
    }
    url = decodeURI(url.substring(url.lastIndexOf('/') + 1));
    var index = url.lastIndexOf('.');
    var fileType = index < 0 ? "" : url.substring(index + 1);

    // Check if there are attributes after the filetype ending
    var specialChars = ['#','?'];
    _.forEach(specialChars, function(specialChar) {
        if ((index = fileType.lastIndexOf(specialChar)) > 0) {
            fileType = fileType.substring(0, index);
        }
    });

    return fileType;
  };
})
.filter('isFileType', function($filter) {
  return function(file, types) {
    var fileType;
    if (file.type) {
      fileType = file.type.substring(file.type.lastIndexOf('/') + 1);
    } else {
      var fileName = (file.name || file);
      fileType = $filter('fileType')(fileName);
      if (_.isEmpty(fileType)) {
        return false;
      }
    }
    var defaultTypes = {
      // FIXME: PDF is not an image! maybe we should introduce a new default type called 'media'?
      // which combines 'images', 'drawables' and 'documents'..., and keep the image as an actual image.
      image: '|bmp|eps|gif|jpeg|jpg|pdf|png|svg|svg+xml|webp|',
      drawable: '|x-xbitmap|bmp|eps|gif|jpeg|jpg|png|svg|svg+xml|webp|',
      thumbnail: '|bmp|gif|jpeg|jpg|png|webp|',
      website: '|htm|html|shtml|xhtml|php|asp|jsp|',
    };
    if (!types.startsWith('|') && defaultTypes[types]) {
      types = defaultTypes[types];
    }
    var type = '|' + _.toLower(fileType) + '|';
    return type === '||' || (types.indexOf(type) !== -1);
  };
})
/**
 * AngularJS default filter with the following expression:
 * "person in people | filter: {name: $select.search, age: $select.search}"
 * performs a AND between 'name: $select.search' and 'age: $select.search'.
 * We want to perform a OR.
 *
 * Used for the ui-select component
 */
.filter('propsFilter', function () {
    return function (items, props, selected) {

        var outNormal = [];
        var outPriorized = [];
        var out = [];

        var keys = Object.keys(props);

        if (_.isArray(items) && !_.isEmpty(props[keys[0]])) {
            items.forEach(function (item) {

                for (var i = 0; i < keys.length; i++) {

                    var prop = keys[i];
                    var text = props[prop].toLowerCase();

                    if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) == 0) {
                        outPriorized.push(item);
                        break;
                    }
                    if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        outNormal.push(item);
                        break;
                    }
                }
            });
            outPriorized.forEach(function (prioItem) {
                out.push(prioItem);
            });
            outNormal.forEach(function (normalItem) {
                out.push(normalItem);
            });

        } else if (!_.isEmpty(selected) && _.isArray(items)) {

            // for normal Enum selected is not an array, but for enumSet it is
            if (!_.isArray(selected)) {
                selected = [selected];
            }
            // set all selected as priorized items in list
            items.forEach(function (item) {

                var added = false;
                selected.forEach(function (selectedKey) {
                    if (_.isEqual(item.key, selectedKey)) {
                        outPriorized.push(item);
                        added = true;
                    }
                });
                if (!added) {
                    outNormal.push(item);
                }
            });
            outPriorized.forEach(function (prioItem) {
                out.push(prioItem);
            });
            outNormal.forEach(function (normalItem) {
                out.push(normalItem);
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }
        return out;
    };
})
.filter('additionalCategoryFormatter', function() {
  return function(category) {
    var result = "";

    if (!category) {
      return result;
    }

    switch (typeof category) {
      case 'string':
        result = category;
        break;
      case 'object':
        if (category.code) {
          if (category.title && (category.code != category.title)) {
            result = category.title + ' [' + category.code + ']';
          } else {
            result = category.code;
          }
        }
        break;
    }

    return result;
  };
})

.filter('translateFilter', function($rootScope) {
    return function(filter) {
        var label;
        if (filter.type === 'CATEGORY') {
            if (_.includes(filter.name, '::')) {
                label = $rootScope.translateCategory(filter.name);
            } else {
                label = $rootScope.translate(filter.name);
            }
        } else if (filter.type === 'DATA_MODEL_DEFINED') {
            label = filter.label;
        } else {
            label = filter.name;
        }
        return label;
    };
})

.filter('categoryNameToLabel', function($rootScope) {
    return function(name) {
      if ($rootScope.dataModel.hasCategory(name)) {
        var category = $rootScope.dataModel.category(name);
        return $rootScope.translateCategory(category);
      } else {
        return name;
      }
    };
})

.filter('categoryNameToId', function() {
    return function(name) {
      return name.replace(/:/g, '_');
    };
})

.filter('gridEnumSetFormatter', function() {
    return function(array, options) {

        if (!_.isArray(array)) {
            return array;
        }

        var values = [];
        var option, translatedOption;
        _.forEach(array, function(value) {
            option = _.find(options, {key: value});
            translatedOption = option ? (option.translatedOption || option.value || value) : value;
            values.push(translatedOption);
        });

        return _.join(values, ', ');
    };
})

.filter('hasChildrenFormatter', function() {
    return function(category) {
      var title = '';
      if (category.emptyDefault) {
        title = category.title;
      } else {
        title = (category.title && category.code) ? (category.title + ' [' + category.code + ']') : category.code;
      }
      return title;
    };
})

.filter('trustAsResourceUrl', function($sce) {
    return function(val) {
        return $sce.trustAsResourceUrl(val);
    };
})

.filter('laxCurrency', function($rootScope, CurrencyService) {
  return function(value, currencyKey) {
    if (!value) {
      return;
    }
    if (!currencyKey) {
      var country = $rootScope.user.country || 'US';
      currencyKey = CurrencyService.getCurrencyKey(country);
    }
    var currencySymbol = CurrencyService.getCurrencySymbol(currencyKey);
    return currencySymbol + value[currencyKey];
  };
})

.filter('filterAttributes', function() {
    return function(attributes, value) {

        var searchFields = ["baseClass", "description", "label", "name", "typeName"];
        var searchFieldObjects = ["values", "keys"];
        var foundObjects = [];
        var tempAttribute;
        var continueSearch;
        value = value.toLowerCase();
        _.forEach(attributes, function(attribute) {
            continueSearch = true;
            _.forEach(searchFields, function(searchField) {
                if (_.has(attribute, searchField)) {
                    if (!_.isEmpty(attribute[searchField])) {
                        if (attribute[searchField].toLowerCase().indexOf(value) > -1) {
                            foundObjects.push(attribute);
                            continueSearch = false;
                            return false;
                        }
                    }
                }
            });
            if (continueSearch) {
                _.forEach(searchFieldObjects, function(searchFieldOption) {
                    if (!continueSearch) {
                        return false;
                    }
                    if (_.has(attribute.params, searchFieldOption)) {
                        _.forEach(attribute.params[searchFieldOption], function(setValue) {
                            if (!continueSearch) {
                                return false;
                            }
                            _.some(_.values(setValue), function(property) {
                                if (!continueSearch) {
                                    return false;
                                }
                                if (!_.isEmpty(property)) {
                                    if (property.toLowerCase().indexOf(value) > -1) {
                                        foundObjects.push(attribute);
                                        continueSearch = false;
                                        return false;
                                    }
                                }
                            });
                        });
                    }
                });
            }
        });
        if (value.length <= 2) {
            return foundObjects.slice(0, 30);
        } else {
            return foundObjects;
        }

    };
})
.filter('sortByVisibleThenHidden', function() {
    return function(attributes, scope) {

        if (_.isEmpty(attributes)) {
            return [];
        }

        var result = [];
        var hiddenAttributes = [];

        _.forEach(attributes, function(attribute) {
            if (attribute.hidden) {
                hiddenAttributes.push(attribute);
            } else {
                result.push(attribute);
            }
        });

        Array.prototype.push.apply(result, hiddenAttributes);

        return result;
    };
})
.filter('showExportFormatsFilter', function(Auth) {
   return function(exportFormats, selectedItems) {

       if (_.isEmpty(selectedItems)) {
           return [];
       }

       var result = [];
       _.forEach(exportFormats, function(exportFormat, index, array) {
           var hasPermission = true;
           _.forEach(selectedItems, function(item, index, array) {
               var context = { contentType: exportFormat.contentType, item: item };
               if (!Auth.hasPermission('exportFormats', 'show', context)) {
                   hasPermission = false;
               }
           });
           if (hasPermission) {
               result.push(exportFormat);
           }
       });

       return result;
   };
})
.filter('dynamicFilter', function($rootScope) {
    return function(value) {
        var filterName = [].splice.call(arguments, 1, 1)[0];
        var filter = $rootScope.getFilter(filterName);
        if (!_.isEmpty(filter)) {
            return filter.apply(null, arguments);
        } else {
            return value;
        }
    };
 })
 .filter('customNgFilter', function() {

    function comparator(actual, expected) {
        if (_.isUndefined(actual)) {
            // No substring matching against `undefined`
            return false;
        }
        if ((actual === null) || (expected === null)) {
            // No substring matching against `null`; only match against `null`
            return actual === expected;
        }
        if (_.isObject(expected) || (_.isObject(actual))) {
            // Should not compare primitives against objects, unless they have custom `toString` method
            return false;
        }

        actual = _.lowerCase('' + actual);
        expected = _.lowerCase('' + expected);
        return actual.indexOf(expected) !== -1;
    }

    function deepCompare(actual, expected, comparator, options) {
        var actualType = (actual === null) ? 'null' : typeof actual;

        if (_.isArray(actual)) {
            // In case `actual` is an array, consider it a match
            // if ANY of it's items matches `expected`
            return actual.some(function(item) {
                return deepCompare(item, expected, comparator, options);
            });
        }

        switch (actualType) {
            case 'object':
                var hasKeys = false, keys = [];
                if (!_.isEmpty(options.keys)) {
                    hasKeys = true;
                    keys = options.keys;
                    if (options.exclude) {
                        var ex_keys = Object.keys(actual).filter(function(k) {
                            return keys.indexOf(k) < 0;
                        });
                        keys = ex_keys;
                    }
                }
                for (var key in actual) {
                    var in_options = options[key] || {};
                    if ((key.charAt(0) !== '$') && (!hasKeys || keys.indexOf(key) > -1) && deepCompare(actual[key], expected, comparator, in_options)) {
                        return true;
                    }
                }
                break;
            case 'function':
                return false;
            default:
                return comparator(actual, expected);
        }
    }

    return function(value, search, options) {
        options = options || {};
        search = search || '';
        return value.filter(function(e) {
            return deepCompare(e, search, comparator, options);
        });
    };

 })
 .filter('formatByte', function () {
    return function (size) {
        var base = 1000;
        var prefixes = ['k','M','G','T','P','E','Z','Y'];
        var exp = Math.log(size) / Math.log(base) | 0;
        return (size / Math.pow(base, exp)).toFixed(1) + ' ' +
            ((exp > 0) ? prefixes[exp - 1] + 'B' : 'Bytes');
    };
})
.filter('defaultGroupTitleFilter', function($filter, $rootScope) {

    function getTranslatedAttributeValue(attributeValue, attributeName) {
        var TRANSLATABLE_ATTRIBUTES = ['Enum', 'EnumSet', 'OpenEnum', 'OpenEnumSet', 'Dimensional', 'Physical'];
        var attribute = $rootScope.dataModel.attribute(attributeName);

        if (!_.isNil(attribute.params.valueAttribute)) {
            attribute = $rootScope.dataModel.getReferencedOptionAttribute(attribute);
        }

        if (_.includes(TRANSLATABLE_ATTRIBUTES, attribute.typeName)) {
            return $rootScope.translateOption(attributeValue, attribute);
        } else if (attribute.typeName === 'Date') {
            return $filter('date')(attributeValue, 'mediumDate');
        } else {
            return _.isObject(attributeValue) ? '' : _.toString(attributeValue);
        }
    }

    return function(entry, entryIndex, filterParameters, attribute, item, user, organization) {

        if (_.isEmpty(filterParameters)) {
            // Construct the title from the first attribute in the group.
            var firstGroupAttribute = null;
            var compositeAttribute = $rootScope.dataModel.getReferencedOptionAttribute(attribute);
            if (!_.isNil(compositeAttribute)) {
                firstGroupAttribute = compositeAttribute.members[0];
            } else {
                var memberAttributes = $rootScope.dataModel.getMemberAttributes(attribute);
                firstGroupAttribute = memberAttributes[0].name;
            }

            if (!_.isNil(firstGroupAttribute)) {
                return getTranslatedAttributeValue(entry[firstGroupAttribute], firstGroupAttribute);
            } else {
                return '';
            }
        } else {
            // if filterParameters were given in the data model, then construct the title from them.
            return _.reduce(filterParameters, function(accumulatedResult, parameter) {
                var attributeValue = entry[parameter];

                if (_.isNil(attributeValue)) {
                    return accumulatedResult;
                } else {
                    return accumulatedResult + (accumulatedResult === '' ? '' : ' - ') + getTranslatedAttributeValue(attributeValue, parameter);
                }

            }, '');
        }
    };
});
